import { HttpClient } from '../../helpers/httpClient';
import { Contact, PersonToAdd, PersonType, RiderAndLesson, RiderLesson } from '../../types/People';
import ErrorBanner from '../../components/ErrorBanner';
import { ChangeEvent, useEffect, useState } from 'react';
import { Lesson } from '../../types/Lessons';
import { useNavigate } from 'react-router-dom';
import { FormSubmitButton } from '../../components/SubmitButton';
import EditAddVolunteerInfo from '../../components/Riders/EditAddVolunteerInfo';
import AddLessons from '../../components/Lessons/AddLessons';
import {
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import AddEmergencyContacts from '../../components/Riders/AddEmergencyContacts';
import DuplicateCreationModal from '../../components/Riders/DuplicateCreationModal';

function AddPerson() {
  const navigate = useNavigate();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [riderLessons, setRiderLessons] = useState<RiderLesson[]>([]);
  const [riderContacts, setRiderContacts] = useState<Contact[]>([]);
  const [personType, setPersonType] = useState<PersonType>(PersonType.None);
  const [person, setPerson] = useState<PersonToAdd>({
    firstName: '',
    lastName: '',
    rider: false,
    staff: false,
    schooling: false,
    eval: false,
    volunteer: false,
    volunteerLevel: '',
  });
  const [people, setPeople] = useState<RiderAndLesson[]>([]);
  const [duplicateCheck, setDuplicateCheck] = useState<boolean>(true);
  const [confirmSubmit, setConfirmSubmit] = useState<RiderAndLesson[]>([]);

  const handleSubmit = async (event: any) => {
    setButtonsDisabled(true);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    try {
      if (person.eval && !person.startDate) {
        setErrorMessage('Eval riders must have an eval date');
        setButtonsDisabled(false);
        return;
      }

      if (form.checkValidity()) {
        if (duplicateCheck) {
          const possibleDuplicate = people.filter((p) => {
            return (
              p.firstName.trim().toLowerCase() === person.firstName.trim().toLowerCase() &&
              p.lastName.trim().toLowerCase() === person.lastName.trim().toLowerCase()
            );
          });

          if (possibleDuplicate.length > 0) {
            setConfirmSubmit(possibleDuplicate);
            setButtonsDisabled(false);
            return;
          }

          const possibleDuplicateFirstNames = people.filter((p) => {
            return p.firstName.trim().toLowerCase() === person.firstName.trim().toLowerCase();
          });

          if (possibleDuplicateFirstNames.length > 0) {
            setConfirmSubmit(possibleDuplicateFirstNames);
            setButtonsDisabled(false);
            return;
          }
        }

        const result = await HttpClient.post('/people', {
          ...person,
          volunteerLevel: person.volunteerLevel ? person.volunteerLevel : undefined,
          lessonInfo: { currentLessons: riderLessons },
          emergencyContacts: riderContacts,
        });
        console.log(result);
        navigate('/people');
      }
    } catch (error) {
      setErrorMessage((error as Error).message);
    }

    setButtonsDisabled(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    let newValue: string | boolean | number = e.target.type === 'checkbox' ? checked : value;

    if (name === 'volunteerLevel') {
      newValue = Number(newValue);
    }

    setPerson((prevState: PersonToAdd) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  async function getLessons(setLessons: Function) {
    try {
      const result = await HttpClient.get('/lessons', {}, { amount: 'currentAndNext' });
      setLessons(result);
    } catch (error) {
      setErrorMessage('Could not load available lessons. Please contact Rebecca.');
    }
  }

  const getPeople = async () => {
    try {
      const result = (await HttpClient.get('/people')) as RiderAndLesson[];
      setPeople(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  };

  useEffect(() => {
    getLessons(setLessons);
    getPeople();
  }, []);

  return (
    <Container style={{ paddingTop: '1em', paddingBottom: '1em' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Add Person
        </Typography>
      </Grid>
      <ErrorBanner errorHeading='Error adding person' errorMessage={errorMessage} />
      <DuplicateCreationModal
        duplicates={confirmSubmit}
        show={confirmSubmit.length > 0}
        handleClose={() => {
          setConfirmSubmit([]);
        }}
        handleConfirm={async () => {
          setDuplicateCheck(false);
          try {
            const result = await HttpClient.post('/people', {
              ...person,
              volunteerLevel: person.volunteerLevel ? person.volunteerLevel : undefined,
              lessonInfo: { currentLessons: riderLessons },
              emergencyContacts: riderContacts,
            });
            console.log(result);
            navigate('/people');
          } catch (error) {
            setErrorMessage((error as Error).message);
          }
        }}
      />
      <form onSubmit={handleSubmit}>
        <Typography variant='h4'>Basic Info</Typography>
        <Grid container spacing={2} marginTop='0.5em'>
          <Grid item xs={12}>
            <TextField
              label='Person Type'
              required
              select
              aria-label='Person Type'
              onChange={(e) => {
                const newValue = e.target.value as PersonType;
                setPersonType(newValue);

                if (newValue === PersonType.Rider) {
                  setPerson({
                    ...person,
                    rider: true,
                    volunteer: false,
                    volunteerLevel: '',
                  });
                } else if (newValue === PersonType.Volunteer) {
                  setPerson({
                    ...person,
                    rider: false,
                    volunteer: true,
                    volunteerLevel: '',
                  });
                } else if (newValue === PersonType.Both) {
                  setPerson({
                    ...person,
                    rider: true,
                    volunteer: true,
                    volunteerLevel: '',
                  });
                } else {
                  setPerson({
                    ...person,
                    rider: false,
                    volunteer: false,
                    volunteerLevel: '',
                  });
                }
              }}
              value={personType}
              name='personType'
              fullWidth
            >
              <MenuItem value={PersonType.Rider}>Rider</MenuItem>
              <MenuItem value={PersonType.Volunteer}>Volunteer</MenuItem>
              <MenuItem value={PersonType.Both}>Both</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        {personType ? (
          <Grid container spacing={2} marginTop='0.5em'>
            <Grid item xs={12} sm={4}>
              <TextField
                label='First Name'
                required
                type='text'
                value={person.firstName}
                placeholder='First Name'
                name='firstName'
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label='Last Name'
                required
                type='text'
                value={person.lastName}
                placeholder='Last Name'
                name='lastName'
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label='Start/Eval Date'
                type='date'
                value={person.startDate ? person.startDate : ''}
                name='startDate'
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        ) : null}
        {personType && (personType === PersonType.Rider || personType === PersonType.Both) ? (
          <>
            <Grid container spacing={2} marginTop='0.5em'>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={<Checkbox onChange={handleInputChange} name='staff' />}
                  label='This person is an employee'
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={<Checkbox onChange={handleInputChange} name='schooling' />}
                  label='This person can do schooling rides'
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={<Checkbox onChange={handleInputChange} name='eval' />}
                  label='This person is an evaluation'
                />
              </Grid>
            </Grid>
          </>
        ) : null}
        {personType ? (
          <AddEmergencyContacts riderContacts={riderContacts} setRiderContacts={setRiderContacts} />
        ) : null}
        {personType && (personType === PersonType.Volunteer || personType === PersonType.Both) ? (
          <>
            <Typography variant='h4' marginTop={'0.5em'} marginBottom={'0.5em'}>
              Volunteer Info
            </Typography>
            <EditAddVolunteerInfo
              level={person.volunteerLevel}
              setLevel={(level: number) => {
                setPerson({
                  ...person,
                  volunteerLevel: level,
                });
              }}
            />
          </>
        ) : null}
        {personType && (personType === PersonType.Rider || personType === PersonType.Both) ? (
          <AddLessons
            riderLessons={riderLessons}
            setRiderLessons={setRiderLessons}
            lessons={lessons}
          />
        ) : null}
        <FormSubmitButton disabled={buttonsDisabled} />
      </form>
    </Container>
  );
}

export default AddPerson;
