import { Horse } from '../../types/Horses';
import { ChangeEvent, useState } from 'react';
import { HttpClient } from '../../helpers/httpClient';
import { useNavigate } from 'react-router-dom';
import { Usage } from '../../types/Usage';
import { FormSubmitButton, SubmitButton } from '../SubmitButton';
import dayjs from 'dayjs';
import { Container, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import HorseHoursTable from './HorseHoursTable';

type KeyValue = {
  [key: string]: { mins: string; hours: string };
};

function AddHorseTableUsage(props: {
  horses: Horse[];
  setErrorMessage: Function;
  date: dayjs.Dayjs;
  type: 'BP' | 'UM' | 'LB' | 'SC';
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const defaultUsage: KeyValue = {};
  for (const key of props.horses) {
    defaultUsage[key.id!] = { mins: '', hours: '' };
  }

  const [horseHours, setHorseHours] = useState<KeyValue>(defaultUsage);
  const [errors, setErrors] = useState<string[]>([]);
  const [umType, setUMType] = useState<string>('');
  const [SCLesson, setSCLesson] = useState<string>('');

  async function handleSubmit() {
    setLoading(true);
    const form: any = document.getElementById('usageForm');
    if (form && !form.checkValidity()) {
      setLoading(false);
      return;
    }

    let usage: Usage[] = [];
    let errors: string[] = [];

    if (props.type === 'SC' && !SCLesson) {
      props.setErrorMessage('Please select AM or PM for the summer camp time.');
      setLoading(false);
      return;
    }

    Object.keys(horseHours).forEach((key) => {
      if (isNaN(Number(horseHours[key].hours)) || isNaN(Number(horseHours[key].mins))) {
        errors.push(key);
      }

      if (!(Number(horseHours[key].hours) === 0 && Number(horseHours[key].mins) === 0)) {
        usage.push({
          usageType: props.type === 'UM' ? umType : props.type,
          horse: key,
          duration: 60 * Number(horseHours[key].hours) + Number(horseHours[key].mins),
          usageDate: props.date.format('YYYY-MM-DD'),
          lesson: props.type === 'SC' ? Number(SCLesson) : undefined,
        });
      }
    });

    setErrors(errors);
    if (errors.length > 0) {
      props.setErrorMessage('Error entering usage');
      setLoading(false);
      return;
    }

    if (usage.length === 0) {
      props.setErrorMessage('Please enter hours for at least one horse');
      setLoading(false);
      return;
    }

    try {
      const result = await HttpClient.post('/usage', usage);
      console.log(result);
      navigate(`/usage/${props.date.format('YYYY-MM-DD')}`);
    } catch (error) {
      props.setErrorMessage((error as Error).message);
    }

    setLoading(false);
  }

  return (
    <Grid container marginBottom='1em'>
      {props.type === 'UM' ? (
        <Grid container marginBottom='1em'>
          <Grid item xs={12}>
            <TextField
              label='Unmounted Type'
              select
              required
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setUMType(e.target.value);
              }}
              value={umType}
              name='type'
              fullWidth
              error={!umType}
              helperText={!umType ? 'Please select and unmounted type' : ''}
            >
              <MenuItem value='UML'>Unmounted Horsemanship/LTR</MenuItem>
              <MenuItem value='UMSC'>Unmounted Summer Camp</MenuItem>
              <MenuItem value='UMBP'>Unmounted Birthday Party</MenuItem>
              <MenuItem value='UMT'>Unmounted Tour</MenuItem>
              <MenuItem value='UMBAC'>Building A Connection</MenuItem>
              <MenuItem value='UMEC'>Equine Encounters</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      ) : null}
      {props.type === 'SC' ? (
        // TODO: Make the lesson numbers not hard coded
        <Grid container marginBottom='1em'>
          <Grid item xs={12}>
            <TextField
              label='Time'
              select
              required
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setSCLesson(e.target.value);
              }}
              value={SCLesson}
              name='time'
              fullWidth
              error={!SCLesson}
              helperText={!SCLesson ? 'Please select AM or PM' : ''}
            >
              <MenuItem value='12'>AM</MenuItem>
              <MenuItem value='13'>PM</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      ) : null}
      <HorseHoursTable
        horses={props.horses}
        horseHours={horseHours}
        setHorseHours={setHorseHours}
        setErrorMessage={props.setErrorMessage}
        errors={errors}
      />
      <SubmitButton disabled={loading} handleSubmit={handleSubmit} />
    </Grid>
  );
}

export default AddHorseTableUsage;
