import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import ErrorBanner from '../../components/ErrorBanner';
import { HttpClient } from '../../helpers/httpClient';
import { Person, RiderAndLesson } from '../../types/People';
import {
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import SearchWithButton from '../../components/SearchWithButtons';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { formatTime } from '../../helpers/utils';

function People() {
  const [searchField, setSearchField] = useState('');
  const [typeFilter, setTypeFilter] = useState<'all' | 'riders' | 'volunteers' | 'staff'>('all');
  const [lessonDay, setLessonDay] = useState('');
  const [lessonTime, setLessonTime] = useState('');
  const [riders, setRiders] = useState<RiderAndLesson[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [windowDimension, setWindowDimension] = useState(window.innerWidth);
  const navigate = useNavigate();

  function handleResize() {
    setWindowDimension(window.innerWidth);
  }

  const isMobile = windowDimension <= 640;
  const dropdownLocatons = [
    {
      display: 'Add Person',
      location: '/people/new',
    },

    {
      display: 'Add Absences',
      location: '/riders/absences',
    },
  ];
  if (!isMobile) {
    dropdownLocatons.push({
      display: 'Bulk Add',
      location: '/people/new/bulk',
    });
  }

  let height = '70vh';
  if (isMobile) {
    height = '50vh';
  }
  if (typeFilter === 'riders') {
    height = '60vh';
  }

  const getRiders = async () => {
    try {
      const result = (await HttpClient.get('/people')) as RiderAndLesson[];
      setRiders(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  };

  let filteredRiders = riders
    .filter((rider) => {
      if (searchField === '') return true;
      return (rider.firstName + ' ' + rider.lastName)
        .toLowerCase()
        .includes(searchField.toLowerCase());
    })
    .filter((rider) => {
      if (typeFilter === 'volunteers') {
        return rider.volunteer;
      } else if (typeFilter === 'riders') {
        return rider.rider;
      } else if (typeFilter === 'staff') {
        return rider.staff;
      }
      return true;
    });

  if (typeFilter === 'riders') {
    // filter by lesson day
    filteredRiders = filteredRiders.filter((rider) => {
      if (!lessonDay) {
        return true;
      }

      if (lessonDay === 'None') {
        return rider.lessonInfo.currentLessons.length === 0;
      } else if (lessonDay === 'Any') {
        return rider.lessonInfo.currentLessons.length > 0;
      }

      return (
        rider.lessonInfo.currentLessons.find((lesson) => {
          return lesson.lessonDay === lessonDay;
        }) != undefined
      );
    });

    // filter by lesson time
    filteredRiders = filteredRiders.filter((rider) => {
      if (!lessonTime) {
        return true;
      }

      return (
        rider.lessonInfo.currentLessons.find((lesson) => {
          return formatTime(lesson.lessonTime) === lessonTime;
        }) != undefined
      );
    });
  }

  const handleRowClick = (params: GridRowParams<Person>) => {
    navigate(
      {
        pathname: `/people/${params.row.id}`,
      },
      {
        state: {
          person: params.row,
        },
      }
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      minWidth: 90,
      maxWidth: 90,
      renderCell: (params) => (
        <strong>
          <Button
            variant='contained'
            color='success'
            onClick={() =>
              navigate(
                {
                  pathname: `/people/${params.row.id}`,
                },
                {
                  state: {
                    person: params.row,
                  },
                }
              )
            }
          >
            <VisibilityIcon />
          </Button>
        </strong>
      ),
    },
    { field: 'firstName', headerName: 'First Name', flex: 1, minWidth: 150 },
    { field: 'lastName', headerName: 'Last Name', flex: 1, minWidth: 150 },
    { field: 'lessons', headerName: 'Lessons', flex: 1, minWidth: 150 },
    {
      field: 'volunteerLevel',
      headerName: 'Volunteer Level',
      flex: 1,
      type: 'number',
      minWidth: 150,
    },
  ];

  let lessonTimes = [];
  for (let i = 0; i < 24; i++) {
    let hour = i;
    let morningNight = ' AM';
    if (i == 0) {
      hour = 12;
    } else if (i == 12) {
      morningNight = ' PM';
    } else if (i > 12) {
      hour = i - 12;
      morningNight = ' PM';
    }

    lessonTimes.push(
      { value: `${i}:00`, display: `${hour}:00${morningNight}` },
      { value: `${i}:30`, display: `${hour}:30${morningNight}` }
    );
  }

  useEffect(() => {
    getRiders();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Container style={{ paddingTop: '1em' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Riders & Volunteers
        </Typography>
      </Grid>
      <SearchWithButton
        label='Search riders'
        searchValue={searchField}
        setSearchValue={setSearchField}
        dropdownOptions={dropdownLocatons}
      />
      <Grid item xs={12} marginBottom={'1em'}>
        <TextField
          select
          label='Person Type'
          value={typeFilter}
          onChange={(event) => {
            // use the as to get rid of the error that the value is a string
            setTypeFilter(event.target.value as 'all');
          }}
          variant='outlined'
          fullWidth
        >
          <MenuItem value='all'>All</MenuItem>
          <MenuItem value='riders'>Riders</MenuItem>
          <MenuItem value='volunteers'>Volunteers</MenuItem>
          <MenuItem value='staff'>Staff</MenuItem>
        </TextField>
      </Grid>
      {typeFilter === 'riders' ? (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              select
              label='Lesson Day'
              value={lessonDay}
              onChange={(event) => {
                setLessonDay(event.target.value);
              }}
              variant='outlined'
              fullWidth
            >
              <MenuItem value=''>
                <em>No restriction</em>
              </MenuItem>
              {[
                {
                  display: 'In a lesson any day',
                  value: 'Any',
                },
                {
                  display: 'Not in a lesson',
                  value: 'None',
                },
                {
                  display: 'Sunday',
                  value: 'Sunday',
                },
                {
                  display: 'Monday',
                  value: 'Monday',
                },

                {
                  display: 'Tuesday',
                  value: 'Tuesday',
                },
                {
                  display: 'Wednesday',
                  value: 'Wednesday',
                },
                {
                  display: 'Thursday',
                  value: 'Thursday',
                },
                {
                  display: 'Friday',
                  value: 'Friday',
                },
                {
                  display: 'Saturday',
                  value: 'Saturday',
                },
              ].map((option, index) => {
                return (
                  <MenuItem value={option.value} key={index}>
                    {option.display}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Time'
              select
              required
              aria-label='Time'
              onChange={(event) => {
                setLessonTime(event.target.value);
              }}
              value={lessonTime}
              name='lessonTime'
              fullWidth
              disabled={!lessonDay || lessonDay === 'None'}
              helperText={
                !lessonDay || lessonDay === 'None'
                  ? 'Please select a lesson day or "In a lesson any day" in order to select a time'
                  : ''
              }
            >
              <MenuItem value=''>
                <em>Any</em>
              </MenuItem>
              {lessonTimes.map((lessonTime) => {
                return (
                  <MenuItem key={lessonTime.value} value={lessonTime.display}>
                    {lessonTime.display}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        </Grid>
      ) : null}
      <ErrorBanner errorHeading='Error getting people' errorMessage={errorMessage} />
      <div style={{ height, width: '100%', marginTop: '1em' }}>
        <DataGrid
          rows={filteredRiders.map((rider) => {
            return {
              ...rider,
              lessons: rider.lessonInfo
                ? rider.lessonInfo.currentLessons
                    .map((lesson) => {
                      return `${lesson.lessonDay ?? ''} ${
                        lesson.lessonTime ? formatTime(lesson.lessonTime) : ''
                      }`;
                    })
                    .join(', ')
                : '',
            };
          })}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          onRowClick={handleRowClick}
        />
      </div>
    </Container>
  );
}

export default People;
