import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Root } from './pages/Root';
import Horses from './pages/Horses/Horses';
import AddHorse from './pages/Horses/AddHorse';
import Usages from './pages/Usage/Usage';
import AddUsage from './pages/Usage/AddUsage';
import AddRider from './pages/People/AddPerson';
import EditUsage from './pages/Usage/EditUsage';
import { RequireAuth } from './components/RequireAuth';
import { Login } from './pages/Login';
import { Authenticator } from '@aws-amplify/ui-react';
import Home from './pages/Home';
import Reports from './pages/Reports/Reports';
import ViewHorse from './pages/Horses/ViewHorse';
import AddLesson from './pages/Lessons/AddLesson';
import AddRiderAbsences from './pages/People/AddRiderAbsences';
import BulkAddRider from './pages/People/BulkAddRider';
import People from './pages/People/People';
import ViewEditPerson from './pages/People/ViewEditPerson';
import Lessons from './pages/Lessons/Lessons';
import VolunteerHours from './pages/VolunteerHours/VolunteerHours';
import AddVolunteerHours from './pages/VolunteerHours/AddVolunteerHours';
import ViewEditVolunteerHours from './pages/VolunteerHours/ViewEditVolunteerHours';
import FarrierInfo from './pages/Horses/FarrierInfo';
import Saddles from './pages/Horses/Saddles';
import EditHorseSaddle from './pages/Horses/EditHorseSaddle';
import DayView from './pages/Board/DayView';
import UpdateBoard from './pages/Board/UpdateBoard';
import AddRiderToBoard from './pages/Board/AddRider';
import Redirect from './pages/Board/Redirect';

const router = createBrowserRouter([
  {
    element: <Root />,
    errorElement: <div>Not Found</div>,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/horses',
        element: (
          <RequireAuth>
            <Horses />
          </RequireAuth>
        ),
      },
      {
        path: '/horses/new',
        element: (
          <RequireAuth>
            <AddHorse />
          </RequireAuth>
        ),
      },
      {
        path: '/horses/:horse',
        element: (
          <RequireAuth>
            <ViewHorse />
          </RequireAuth>
        ),
      },
      {
        path: '/horses/farrier/new',
        element: (
          <RequireAuth>
            <FarrierInfo />
          </RequireAuth>
        ),
      },
      {
        path: '/horses/saddles',
        element: (
          <RequireAuth>
            <Saddles />
          </RequireAuth>
        ),
      },
      {
        path: '/horses/saddles/:horse',
        element: (
          <RequireAuth>
            <EditHorseSaddle />
          </RequireAuth>
        ),
      },
      {
        path: '/usage/:date',
        element: (
          <RequireAuth>
            <Usages />
          </RequireAuth>
        ),
      },
      {
        path: '/usage/new',
        element: (
          <RequireAuth>
            <AddUsage />
          </RequireAuth>
        ),
      },
      {
        path: '/usage/edit/:date/:horse',
        element: (
          <RequireAuth>
            <EditUsage />
          </RequireAuth>
        ),
      },
      {
        path: '/people',
        element: (
          <RequireAuth>
            <People />
          </RequireAuth>
        ),
      },
      {
        path: '/people/new',
        element: (
          <RequireAuth>
            <AddRider />
          </RequireAuth>
        ),
      },
      {
        path: '/people/new/bulk',
        element: (
          <RequireAuth>
            <BulkAddRider />
          </RequireAuth>
        ),
      },
      {
        path: '/people/:person',
        element: (
          <RequireAuth>
            <ViewEditPerson />
          </RequireAuth>
        ),
      },
      {
        path: '/riders/absences',
        element: (
          <RequireAuth>
            <AddRiderAbsences />
          </RequireAuth>
        ),
      },
      {
        path: '/reports',
        element: (
          <RequireAuth>
            <Reports />
          </RequireAuth>
        ),
      },
      {
        path: '/lessons',
        element: (
          <RequireAuth>
            <Lessons />
          </RequireAuth>
        ),
      },
      {
        path: '/lessons/new',
        element: (
          <RequireAuth>
            <AddLesson />
          </RequireAuth>
        ),
      },
      {
        path: '/volunteers/hours',
        element: (
          <RequireAuth>
            <VolunteerHours />
          </RequireAuth>
        ),
      },
      {
        path: '/volunteers/hours/new',
        element: (
          <RequireAuth>
            <AddVolunteerHours />
          </RequireAuth>
        ),
      },
      {
        path: '/volunteers/hours/:volunteer',
        element: (
          <RequireAuth>
            <ViewEditVolunteerHours />
          </RequireAuth>
        ),
      },
      {
        path: '/board',
        element: (
          <RequireAuth>
            <Redirect />
          </RequireAuth>
        ),
      },
      {
        path: '/board/:date',
        element: (
          <RequireAuth>
            <DayView />
          </RequireAuth>
        ),
      },
      {
        path: '/board/:date/change',
        element: (
          <RequireAuth>
            <UpdateBoard />
          </RequireAuth>
        ),
      },
      {
        path: '/board/:date/change/rider',
        element: (
          <RequireAuth>
            <AddRiderToBoard />
          </RequireAuth>
        ),
      },
    ],
  },
]);

// A comment
function App() {
  return (
    <Authenticator.Provider>
      <RouterProvider router={router} />
    </Authenticator.Provider>
  );
}

export default App;
