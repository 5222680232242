import { useState } from 'react';
import BasicModal from './BasicModal';
import { Button, Typography } from '@mui/material';

function ConfirmHorsemanshipSubmitModal(props: {
  show: boolean;
  handleClose: Function;
  handleConfirm: Function;
  missingRiderHorse: string[];
}) {
  return (
    <BasicModal
      open={props.show}
      onClose={() => {
        props.handleClose();
      }}
      title='Confirm Usage Submission'
      submitButton={{
        color: 'success',
        text: 'Submit',
        onClick: async (event) => {
          await props.handleConfirm();
        },
      }}
      closeButton
    >
      <Typography variant='subtitle1' marginBottom='1em'>
        The following riders are missing horses. Are you sure you want to submit?
      </Typography>
      <Typography variant='body2'>{props.missingRiderHorse.join(', ')}</Typography>
    </BasicModal>
  );
}

export default ConfirmHorsemanshipSubmitModal;
