import ErrorBanner from '../../components/ErrorBanner';
import { useEffect, useState } from 'react';
import { HttpClient } from '../../helpers/httpClient';
import { Lesson } from '../../types/Lessons';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import DisplayHorsemanshipUsage from '../../components/Usage/DisplayHorsemanshipUsage';
import DisplayFreeLungeUsage from '../../components/Usage/DisplayFreeLungeUsage';
import DisplayHourMinUsage from '../../components/Usage/DisplayHourMinUsage';
import DisplaySCUsage from '../../components/Usage/DisplaySCUsage';
import { Horse } from '../../types/Horses';
import { Person } from '../../types/People';
import { Button, Container, Grid, Typography } from '@mui/material';

function EditUsage() {
  const navigate = useNavigate();
  const params = useParams();
  const horseParam = params.horse!;
  const date = params.date!;

  const [riders, setRiders] = useState<Person[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [horse, setHorse] = useState<Horse | undefined>();

  async function getRiders(setRiders: Function) {
    try {
      const result: Person[] = await HttpClient.get('/people');
      setRiders(
        result.filter((obj, index) => result.findIndex((rider) => rider.id === obj.id) === index)
      );
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  async function getLessons(setLessons: Function) {
    try {
      const result = await HttpClient.get('/lessons');
      setLessons(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  async function getHorses(setHorse: Function, selectedHorse: string) {
    try {
      const result: Horse[] = await HttpClient.get('/horses');
      setHorse(result.find((horse: Horse) => horse.id === selectedHorse));
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  useEffect(() => {
    getRiders(setRiders);
    getLessons(setLessons);
    getHorses(setHorse, horseParam);
  }, []);

  return (
    <Container>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Edit Usage {horse ? `for ${horse.barnName}` : ''}
        </Typography>
      </Grid>
      <ErrorBanner errorHeading='Error editing usage' errorMessage={errorMessage} />
      <DisplayHorsemanshipUsage
        riders={riders}
        lessons={lessons}
        selectedDate={date}
        selectedHorse={horseParam}
        setErrorMessage={setErrorMessage}
      />
      <DisplayFreeLungeUsage
        selectedDate={date}
        selectedHorse={horseParam}
        setErrorMessage={setErrorMessage}
      />
      <DisplayHourMinUsage
        usageType='LB'
        title='Little Bits'
        selectedDate={date}
        selectedHorse={horseParam}
        setErrorMessage={setErrorMessage}
      />
      <DisplayHourMinUsage
        usageType='UM'
        title='Unmounted'
        typeChanges={[
          { code: 'UML', description: 'Unmounted Lesson' },
          { code: 'UMBP', description: 'Unmounted Birthday Party' },
          { code: 'UMT', description: 'Unmounted Tour' },
          { code: 'UMSC', description: 'Unmounted Summer Camp' },
        ]}
        unstrictFilter={true}
        selectedDate={date}
        selectedHorse={horseParam}
        setErrorMessage={setErrorMessage}
      />
      <DisplaySCUsage
        selectedDate={date}
        selectedHorse={horseParam}
        setErrorMessage={setErrorMessage}
      />
      <DisplayHourMinUsage
        usageType='HR'
        title='Hack Ride'
        riders={riders}
        selectedDate={date}
        selectedHorse={horseParam}
        setErrorMessage={setErrorMessage}
      />
      <DisplayHourMinUsage
        usageType='BP'
        title='Birthday Party'
        selectedDate={date}
        selectedHorse={horseParam}
        setErrorMessage={setErrorMessage}
      />
      <DisplayHourMinUsage
        usageType='P'
        title='Private'
        riders={riders}
        selectedDate={date}
        selectedHorse={horseParam}
        setErrorMessage={setErrorMessage}
      />
      <DisplayHourMinUsage
        usageType='SP'
        title='Semi-Private'
        riders={riders}
        selectedDate={date}
        selectedHorse={horseParam}
        setErrorMessage={setErrorMessage}
      />
      <DisplayHourMinUsage
        usageType='S'
        title='Schooling'
        riders={riders}
        selectedDate={date}
        selectedHorse={horseParam}
        setErrorMessage={setErrorMessage}
      />
      <DisplayHourMinUsage
        usageType='LR'
        title='Lease Ride'
        riders={riders}
        selectedDate={date}
        selectedHorse={horseParam}
        setErrorMessage={setErrorMessage}
      />
      <DisplayHourMinUsage
        usageType='BR'
        title='Boarder Ride'
        riders={riders}
        selectedDate={date}
        selectedHorse={horseParam}
        setErrorMessage={setErrorMessage}
      />
      <DisplayHourMinUsage
        usageType='L'
        title='Lunging'
        selectedDate={date}
        selectedHorse={horseParam}
        setErrorMessage={setErrorMessage}
      />
      <Grid container style={{ padding: '1em' }}>
        <Grid item xs={12}>
          <Button
            variant='contained'
            color='success'
            id='button-return-to-usage'
            onClick={() => navigate(`/usage/${date}`)}
            fullWidth
          >
            Return to View Usage
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default EditUsage;
