import { Box, Grid, TextField } from '@mui/material';
import { HorseFeed } from '../../types/Horses';
import { ChangeEvent, useState } from 'react';

function Hay(props: {
  hay: HorseFeed;
  handleInputChange: Function;
  default: {
    first: string;
    second: string;
    third: string;
    fourth: string;
  };
}) {
  const [hayFeed, setHayFeed] = useState<{
    first: string;
    second: string;
    third: string;
    fourth: string;
  }>(props.default);
  const [errors, setErrors] = useState<{
    first: boolean;
    second: boolean;
    third: boolean;
    fourth: boolean;
  }>({
    first: false,
    second: false,
    third: false,
    fourth: false,
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(name, value);

    let newErrors = {
      ...errors,
      [name]: false,
    };

    if (isNaN(Number(value)) || !value) {
      newErrors = {
        ...newErrors,
        [name]: true,
      };
    }

    const newHay = {
      ...hayFeed,
      [name]: value,
    };

    setHayFeed(newHay);
    setErrors(newErrors);

    props.handleInputChange({
      amount: `${newHay.first}-${newHay.second}-${newHay.third}-${newHay.fourth}`,
      valid: !(newErrors.first || newErrors.second || newErrors.third || newErrors.fourth),
    });
  };

  return (
    <>
      <Grid container alignItems='center' marginTop={'0.5em'} columnSpacing={2} rowSpacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            type='text'
            required
            label='First Feed'
            onChange={handleInputChange}
            value={hayFeed.first}
            name='first'
            error={errors.first || hayFeed.first === ''}
            helperText={
              hayFeed.first === ''
                ? 'Enter an amount.'
                : errors.first
                ? 'Must be a number.'
                : 'Looks good!'
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type='text'
            required
            label='Second Feed'
            onChange={handleInputChange}
            value={hayFeed.second}
            name='second'
            error={errors.second || hayFeed.second === ''}
            helperText={
              hayFeed.second === ''
                ? 'Enter an amount.'
                : errors.second
                ? 'Must be a number.'
                : 'Looks good!'
            }
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container alignItems='center' marginTop={'0.5em'} columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            type='text'
            required
            label='Third Feed'
            onChange={handleInputChange}
            value={hayFeed.third}
            name='third'
            error={errors.third || hayFeed.third === ''}
            helperText={
              hayFeed.third === ''
                ? 'Enter an amount.'
                : errors.third
                ? 'Must be a number.'
                : 'Looks good!'
            }
            fullWidth
          />
        </Grid>{' '}
        <Grid item xs={12} sm={6}>
          <TextField
            type='text'
            required
            label='Fourth Feed'
            onChange={handleInputChange}
            value={hayFeed.fourth}
            name='fourth'
            error={errors.fourth || hayFeed.fourth === ''}
            helperText={
              hayFeed.fourth === ''
                ? 'Enter an amount.'
                : errors.fourth
                ? 'Must be a number.'
                : 'Looks good!'
            }
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Hay;
