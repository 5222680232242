import { ChangeEvent } from 'react';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { Contact } from '../../types/People';
import { Box, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

function EmergencyContact(props: {
  riderContact: Contact;
  setRiderContact: Function;
  index?: number;
}) {
  const { riderContact, setRiderContact, index } = props;

  const handleContactChange = (e: ChangeEvent<HTMLInputElement>, field: string) => {
    const { value } = e.target;
    let newValue: string | number = value;
    if (field === 'phoneNumber') {
      newValue = value.replace(/[^\d\s]/g, '');
    }

    props.setRiderContact({ ...riderContact, [field]: newValue });
  };

  return (
    <Box
      sx={{ backgroundColor: index && index % 2 === 0 ? '#f5f5f5' : 'white' }}
      padding={'1em'}
      key={riderContact.id}
    >
      {index ? (
        <Typography variant='h6' fontWeight={'bold'}>
          Emergency Contact #{index + 1}
        </Typography>
      ) : null}
      <Grid
        container
        spacing={2}
        marginTop='0.5em'
        sx={{ backgroundColor: index && index % 2 === 0 ? '#f5f5f5' : 'white' }}
      >
        <Grid item xs={12} sm={6}>
          <TextField
            label='First Name'
            aria-label='First Name'
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleContactChange(event, 'firstName');
            }}
            value={riderContact.firstName ? riderContact.firstName : ''}
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label='Last Name'
            aria-label='Last Name'
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleContactChange(event, 'lastName');
            }}
            value={riderContact.lastName ? riderContact.lastName : ''}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} marginTop='0.5em'>
        <Grid item xs={12} sm={6}>
          <TextField
            label='Relationship'
            select
            aria-label='Relationship'
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleContactChange(event, 'relationship');
            }}
            value={riderContact.relationship ? riderContact.relationship : ''}
            fullWidth
            required
          >
            <MenuItem value='Parent'>Parent</MenuItem>
            <MenuItem value='Grandparent'>Grandparent</MenuItem>
            <MenuItem value='Spouse'>Spouse/Partner</MenuItem>
            <MenuItem value='Friend'>Friend</MenuItem>
            <MenuItem value='Other'>Other</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type='tel'
            label='Phone Number'
            aria-label='Phone Number'
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleContactChange(event, 'phoneNumber');
            }}
            value={riderContact.phoneNumber ? riderContact.phoneNumber : ''}
            fullWidth
            required
            error={riderContact.phoneNumber && riderContact.phoneNumber.length != 10 ? true : false}
            helperText={
              !riderContact.phoneNumber || riderContact.phoneNumber.length != 10
                ? 'Phone number must be 10 digits'
                : 'Looks good!'
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default EmergencyContact;
