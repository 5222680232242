import React, { ChangeEvent } from 'react';
import {
  TextField,
  Grid,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import { HorseSaddle, Pad, Saddle } from '../../types/Horses';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import KnowledgeableSelect from '../KnowledgeableSelect';

const HorseSaddleComponent = (props: {
  saddleList: Saddle[];
  saddle: HorseSaddle;
  index: number;
  onSaddleChange: Function;
  onSaddleRemove?: Function;
}) => {
  const { saddleList, saddle, index, onSaddleChange, onSaddleRemove } = props;

  const pads: Pad[] = [
    'Ogilvy',
    'Wool',
    'Lamicell',
    'Prolite',
    'Half pad',
    'Riser',
    'Thinline',
    'Foam',
  ];

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={1} style={{ paddingLeft: '0em' }}>
        <DragIndicatorIcon />
      </Grid>

      <Grid item xs={11}>
        <Grid item paddingBottom={'0.5em'}>
          <Typography variant='body1'> {`Choice: ${index + 1}`}</Typography>
        </Grid>
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <KnowledgeableSelect
              options={saddleList.map((saddle) => {
                return {
                  display: saddle.smallSaddle ? `${saddle.saddle} (small saddle)` : saddle.saddle,
                  otherDetails: saddle,
                };
              })}
              onChange={(newSaddle: Saddle | undefined) => {
                if (!newSaddle) {
                  onSaddleChange({
                    ...saddle,
                    saddleId: '',
                  });
                  return;
                }

                onSaddleChange({
                  ...saddle,
                  saddleId: newSaddle.id,
                });
              }}
              required={true}
              disabled={false}
              label='Saddle'
              initialSelected={
                saddle.saddle ? saddleList.findIndex((obj) => obj.id === saddle.saddleId)! : -1
              }
              defaultOption={{
                id: '',
                saddle: '',
                smallSaddle: false,
              }}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              {pads.map((pad: Pad, idx) => (
                <Grid item key={idx}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={saddle.padsArray?.includes(pad)}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const checkbox = event.target;
                          let padsArr = [...saddle.padsArray];

                          if (checkbox.checked) {
                            // Add the value to the array if checked
                            if (!saddle.pads.includes(pad)) {
                              padsArr.push(pad);
                            }
                          } else {
                            // Remove the value from the array if unchecked
                            padsArr = padsArr.filter((padA) => padA !== pad);
                          }

                          onSaddleChange({
                            ...saddle,
                            padsArray: padsArr,
                          });
                        }}
                      />
                    }
                    label={pad}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label='Other Pads'
              value={saddle.additions}
              variant='outlined'
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                console.log(event.target.value);
                onSaddleChange({
                  ...saddle,
                  additions: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={saddle.priorityFit}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        onSaddleChange({
                          ...saddle,
                          priorityFit: event.target.checked,
                        });
                      }}
                    />
                  }
                  label={'Gets saddle priority'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label='Notes'
              value={saddle.notes}
              variant='outlined'
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                onSaddleChange({
                  ...saddle,
                  notes: event.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HorseSaddleComponent;
