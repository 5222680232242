import { Horse } from '../../types/Horses';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { ChangeEvent, MouseEvent, useState } from 'react';
import { HttpClient } from '../../helpers/httpClient';
import { useNavigate } from 'react-router-dom';
import { Usage } from '../../types/Usage';
import HorseSelect from '../HorseSelect';
import DeleteIcon from '@mui/icons-material/Delete';
import { v4 as uuidv4 } from 'uuid';
import { SubmitButton } from '../SubmitButton';
import { Person } from '../../types/People';
import dayjs from 'dayjs';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

function AddHackRideOrPrivateUsage(props: {
  riders: Person[];
  horses: Horse[];
  usageType: 'HR' | 'P';
  setErrorMessage: Function;
  date: dayjs.Dayjs;
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [duration, setDuration] = useState({ mins: '', hours: '' });
  const [usage, setUsage] = useState<
    {
      horse: string;
      rider: { id: string; name: string; firstName: string; lastName: string };
      id: string | number;
    }[]
  >([
    {
      rider: { id: '', name: '', firstName: '', lastName: '' },
      horse: '',
      id: 0,
    },
  ]);

  const [errors, setErrors] = useState<string[]>([]);

  function handleHoursChange(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const newDuration = {
      ...duration,
    };

    if (name === 'hours') {
      newDuration.hours = e.target.value;
    } else {
      newDuration.mins = e.target.value;
    }

    setDuration(newDuration);
  }

  function handleRiderChange(
    rider: { id: string; name: string; firstName: string; lastName: string },
    index: number
  ) {
    const nextRiders = usage.map((usage: any, i: number) => {
      if (i === index) {
        return {
          ...usage,
          rider: rider,
        };
      }

      return usage;
    });

    console.log(nextRiders);
    setUsage(nextRiders);
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>, index: number) {
    let value: string | number | boolean = e.target.value;

    const nextRiders = usage.map((usage: any, i: number) => {
      if (i === index) {
        return {
          ...usage,
          [e.target.name]: value,
        };
      }

      return usage;
    });

    console.log(nextRiders);
    setUsage(nextRiders);
  }

  function addRider() {
    setUsage([
      ...usage,
      {
        rider: { id: '', name: '', firstName: '', lastName: '' },
        horse: '',
        id: uuidv4(),
      },
    ]);
  }

  function removeAdded(event: MouseEvent<HTMLButtonElement>) {
    let node: HTMLInputElement | null = event.target as HTMLInputElement;
    let count = 4;
    while (node && !node.value && count > 0) {
      node = node.parentElement as HTMLInputElement;
      count--;
    }

    let buttonIndex = node.value;
    const newHorsemanship = usage.filter((usage: any, index: number) => {
      return String(index) !== buttonIndex;
    });
    setUsage(newHorsemanship);
  }

  async function handleSubmit() {
    setLoading(true);
    if (!duration || isNaN(Number(duration.hours)) || isNaN(Number(duration.mins))) {
      props.setErrorMessage('Hours and mins must be a number');
      setLoading(false);
      return;
    }

    if (60 * Number(duration.hours) + Number(duration.mins) <= 0) {
      props.setErrorMessage('Duration must be greater than 0');
      setLoading(false);
      return;
    }

    let usageType = 'HR';
    if (props.usageType === 'P') {
      usageType = usage.length > 1 ? 'SP' : 'P';
    }

    const allUsage: Usage[] = [];
    const errors: string[] = [];
    usage.forEach((u: any) => {
      if (!u.rider.id) {
        errors.push(u.id);
        return;
      }
      if (!u.horse) {
        errors.push(u.id);
        return;
      }

      allUsage.push({
        usageType,
        rider: u.rider.id,
        horse: u.horse,
        duration: 60 * Number(duration.hours) + Number(duration.mins),
        usageDate: props.date.format('YYYY-MM-DD'),
      });
    });

    if (errors.length > 0) {
      props.setErrorMessage('Missing horse or rider');
      setErrors(errors);
      setLoading(false);
      return;
    }

    props.setErrorMessage('');

    try {
      const result = await HttpClient.post('/usage', allUsage);
      console.log(result);
      navigate(`/usage/${props.date.format('YYYY-MM-DD')}`);
    } catch (error) {
      props.setErrorMessage((error as Error).message);
    }

    setLoading(false);
  }

  return (
    <div>
      <Grid container marginBottom='1em' spacing={2}>
        <Grid item xs={6}>
          <TextField
            variant='outlined'
            label='Hours'
            type='tel'
            name='hours'
            onChange={handleHoursChange}
            value={duration.hours}
            InputProps={{
              endAdornment: <InputAdornment position='end'>hours</InputAdornment>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant='outlined'
            label='Mins'
            type='tel'
            aria-label='mins'
            name='mins'
            onChange={handleHoursChange}
            value={duration.mins}
            InputProps={{
              endAdornment: <InputAdornment position='end'>mins</InputAdornment>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Box marginBottom={'1em'}>
        <Typography variant='h6'>Riders</Typography>
      </Box>
      {usage.map((u: any, index: number) => {
        return (
          <Box key={index}>
            <Grid
              container
              columnSpacing={2}
              sx={{
                backgroundColor: errors.includes(u.id)
                  ? 'rgb(248, 215, 218)'
                  : index % 2 === 0
                  ? '#f5f5f5'
                  : 'white',
              }}
              marginLeft='-0.5em'
              paddingRight='1em'
            >
              <Grid item xs={12}>
                <Typography
                  variant='body1'
                  sx={{
                    fontWeight: 'bold',
                    marginTop: '0.5em',
                  }}
                >
                  Rider #{index + 1}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems={'center'}
              justifyContent={'center'}
              spacing={2}
              id={`${index}-editRider`}
              sx={{
                backgroundColor: errors.includes(u.id)
                  ? 'rgb(248, 215, 218)'
                  : index % 2 === 0
                  ? '#f5f5f5'
                  : 'white',
              }}
              columnSpacing={2}
              paddingTop='1em'
              paddingBottom='1em'
              paddingRight='1em'
              marginLeft='-0.5em'
            >
              <Grid item xs={12} sm={5}>
                <Autocomplete
                  disablePortal
                  getOptionLabel={(option) => option.name}
                  getOptionKey={(option) => option.id}
                  value={u.rider.id ? u.rider : null}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={props.riders.map((rider) => {
                    return {
                      id: rider.id,
                      name: rider.firstName + ' ' + rider.lastName,
                      firstName: rider.firstName,
                      lastName: rider.lastName,
                    };
                  })}
                  renderInput={(params) => <TextField {...params} label='Rider' />}
                  onChange={(event, selectedOption) => {
                    handleRiderChange(selectedOption, index);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <HorseSelect
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(e, index);
                  }}
                  horses={props.horses}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  variant='contained'
                  color='error'
                  id={`delete-${index}`}
                  value={index}
                  onClick={removeAdded}
                  fullWidth
                >
                  <DeleteIcon style={{ width: '20', paddingBottom: '3.5px' }} />
                </Button>
              </Grid>
            </Grid>
          </Box>
        );
      })}

      <Grid container marginTop='1em' marginBottom='1em' justifyContent='center'>
        <Grid item>
          <Button variant='contained' color='inherit' id='add-rider' onClick={addRider}>
            <AddSharpIcon style={{ width: '20', paddingBottom: '3.5px' }} /> Add Rider
          </Button>
        </Grid>
      </Grid>
      <SubmitButton handleSubmit={handleSubmit} disabled={loading} />
    </div>
  );
}

export default AddHackRideOrPrivateUsage;
