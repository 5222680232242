import { useNavigate } from 'react-router-dom';
import { formatTime, formatUsageTime } from '../../helpers/utils';
import { Horse } from '../../types/Horses';
import { Lesson } from '../../types/Lessons';
import { DailyUsage, DailyUsageValue } from '../../types/Usage';
import dayjs from 'dayjs';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';

const dayString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

// Create a custom theme using createTheme
const theme = createTheme();

function formatUsage(u: DailyUsageValue[], horseId: string, horseName: string) {
  let riderUsages: string[] = [];
  let otherUsages: string[] = [];

  u.forEach((usageDuration) => {
    let horseUsage = '';
    if (usageDuration.firstName && usageDuration.lastName) {
      horseUsage += usageDuration.firstName + ' ' + usageDuration.lastName[0];

      let inBrackets = usageDuration.usageType;
      if (usageDuration.lessonTime && usageDuration.usageType == 'G') {
        inBrackets = formatTime(usageDuration.lessonTime);
      }

      riderUsages.push(
        `${horseUsage} (${inBrackets}): ${
          usageDuration.usageType === 'RA' ? 'RA' : formatUsageTime(usageDuration.duration)
        }`
      );
    } else if (usageDuration.usageType) {
      if (usageDuration.usageType.includes('UM')) {
        horseUsage += 'UM';
      } else {
        horseUsage += usageDuration.usageType;
      }

      if (usageDuration.usageType === 'SC') {
        horseUsage += formatTime(usageDuration.lessonTime) === '9:00 AM' ? ' (AM)' : ' (PM)';
      }

      otherUsages.push(
        usageDuration.usageType === 'FL'
          ? `${horseUsage}`
          : `${horseUsage}: ${formatUsageTime(usageDuration.duration)}`
      );
    }
  });

  return { id: horseId, riderUsage: riderUsages, otherUsage: otherUsages, horse: horseName };
}

function DailyUsages(props: {
  usage: DailyUsage;
  horses: Horse[];
  lessons: Lesson[];
  selectedDate: dayjs.Dayjs;
}) {
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const navigate = useNavigate();
  if (Object.keys(props.usage).length === 0) {
    return null;
  }

  const lessons = props.lessons.filter(
    (lesson) => lesson.lessonDay === dayString[props.selectedDate.day()]
  );

  if (!lessons) {
    return null;
  }

  const rows = props.horses.map((horse) => {
    return formatUsage(props.usage[horse.id!], horse.id!, horse.barnName);
  });

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper} sx={{ maxHeight: isXs ? '55vh' : '70vh' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', width: '90px' }}></TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Horse</TableCell>
              <TableCell sx={{ fontWeight: 'bold', minWidth: '110px' }}>Usage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  onClick={() => {
                    navigate(
                      {
                        pathname: `/usage/edit/${props.selectedDate.format('YYYY-MM-DD')}/${
                          row.id
                        }`,
                      },
                      {
                        state: {
                          usage: props.usage[row.id!],
                          date: props.selectedDate.format('YYYY-MM-DD'),
                          lessons,
                          horse: row.id,
                        },
                      }
                    );
                  }}
                >
                  <TableCell sx={{ width: '90px', zIndex: 10 }}>
                    <Button
                      variant='contained'
                      color='success'
                      id={`edit-usage-${row.id}`}
                      onClick={() =>
                        navigate(
                          {
                            pathname: `/usage/edit/${props.selectedDate.format('YYYY-MM-DD')}/${
                              row.id
                            }`,
                          },
                          {
                            state: {
                              usage: props.usage[row.id!],
                              date: props.selectedDate.format('YYYY-MM-DD'),
                              lessons,
                              horse: row.id,
                            },
                          }
                        )
                      }
                    >
                      <EditIcon />
                    </Button>
                  </TableCell>
                  <TableCell sx={{ minWidth: '100px' }}>{row.horse}</TableCell>
                  <TableCell sx={{ minWidth: '100px' }}>
                    <span>
                      {row.riderUsage.concat(row.otherUsage).map((item: string, index: number) => (
                        <React.Fragment key={index}>
                          {item}
                          {index !== row.riderUsage.length + row.otherUsage.length - 1 && (
                            <>
                              <br />
                              <br />
                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
}

export default DailyUsages;
