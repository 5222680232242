import { useState } from 'react';
import BasicModal from './BasicModal';
import { Button, TextField } from '@mui/material';
import ErrorBanner from '../ErrorBanner';
import { HttpClient } from '../../helpers/httpClient';

function RemoveFromLessonModal(props: {
  show: boolean;
  handleClose: () => void;
  handleConfirm: Function;
  leftClass: string;
  personId: string;
  lessonId: string;
}) {
  const [leftClass, setLeftClass] = useState(props.leftClass ? props.leftClass : new Date());
  const [errorMessage, setErrorMessage] = useState('');

  async function handleDelete() {
    setErrorMessage('');

    try {
      await HttpClient.delete(
        `/riders/${props.personId}/lessons/${props.lessonId}?date=${leftClass}`
      );
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  return (
    <BasicModal
      onClose={props.handleClose}
      open={props.show}
      title="When is the rider's last class?"
      submitButton={{
        color: 'error',
        text: 'Delete',
        onClick: async (event) => {
          await handleDelete();
          await props.handleConfirm();
        },
      }}
      closeButton
    >
      <ErrorBanner errorHeading='Error' errorMessage={errorMessage} />
      <TextField
        label='Removal date'
        type='date'
        value={leftClass}
        name='leftClass'
        onChange={(event) => {
          setLeftClass(event.target.value);
        }}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
    </BasicModal>
  );
}

export default RemoveFromLessonModal;
