import { useNavigate } from 'react-router-dom';
import { Horse } from '../../types/Horses';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';

function HorseCard(props: { horse: Horse }) {
  const navigate = useNavigate();
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea
        onClick={() => {
          navigate(`/horses/${props.horse.id}`);
        }}
      >
        <CardMedia
          component='img'
          height='300'
          image={
            props.horse.hasPhoto ? `/images/${props.horse.barnName}.jpeg` : '/images/default.jpeg'
          }
          alt='default image'
        />
        <CardContent>
          <Typography gutterBottom variant='h5' component='div'>
            {props.horse.barnName}
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            {props.horse.birthDate}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default HorseCard;
