import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SideNav } from '../components/SideNav/SideNav';
import { useAuthenticator } from '@aws-amplify/ui-react';

export function Root() {
  const [windowDimension, setWindowDimension] = useState(0);
  const { route, signOut } = useAuthenticator((context) => [context.route, context.signOut]);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowDimension <= 640;
  if (route !== 'authenticated') {
    return <Outlet />;
  }

  return (
    <>
      <div style={{ display: 'flex', height: '100vh' }}>
        <SideNav isMobile={isMobile} />
        <div
          style={{
            flex: 1,
            overflowY: 'auto',
          }}
        >
          <div
            style={{
              maxHeight: '100vh',
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
