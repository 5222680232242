import { ChangeEvent, useState } from 'react';
import { VolunteerHourAndName } from '../../types/People';
import { HttpClient } from '../../helpers/httpClient';
import { Grid, TextField } from '@mui/material';
import BasicModal from '../../components/Modals/BasicModal';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ErrorBanner from '../../components/ErrorBanner';
import dayjs from 'dayjs';
import HorseHours from '../../components/Usage/HorseHours';

function EditVolunteer(props: {
  volunteerHour?: VolunteerHourAndName & { hours: string; mins: string };
  setVolunteerHour: Function;
  onSubmit: Function;
  onClose: () => void;
  show: boolean;
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const { volunteerHour, setVolunteerHour, onSubmit, onClose, show } = props;

  if (!volunteerHour) {
    return null;
  }

  console.log(volunteerHour);

  const handleSubmit = async () => {
    console.log(volunteerHour.hours, volunteerHour.mins);
    setErrorMessage('');
    if (isNaN(Number(volunteerHour.hours)) || isNaN(Number(volunteerHour.mins))) {
      setErrorMessage('Hours and mins must be a number.');
      return;
    }

    if (Number(volunteerHour.hours) === 0 && Number(volunteerHour.mins) === 0) {
      setErrorMessage('Hours and mins must be greater than 0');
      return;
    }

    try {
      const result = await HttpClient.put(`/volunteers/hours/hour/${volunteerHour.id}`, {
        ...volunteerHour,
        duration: 60 * Number(volunteerHour.hours) + Number(volunteerHour.mins),
      });

      onSubmit();
      onClose();
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  };

  function handleHoursChange(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;

    const updated = volunteerHour;

    if (updated) {
      if (name === 'hours') {
        updated.hours = e.target.value;
      } else {
        updated.mins = e.target.value;
      }
    }
    setVolunteerHour(updated);
  }

  return (
    <BasicModal
      onClose={onClose}
      open={show}
      title='Update Volunteer Hours'
      submitButton={{
        color: 'success',
        text: 'Update',
        onClick: async (event) => {
          await handleSubmit();
        },
      }}
      closeButton
    >
      <ErrorBanner errorHeading='Error editing volunteer hours' errorMessage={errorMessage} />
      <form id='volunteerForm'>
        <Grid container spacing={2} marginBottom={'1em'}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='Volunteer Date'
                value={dayjs(volunteerHour.date)}
                onChange={(e: dayjs.Dayjs | null) =>
                  setVolunteerHour({ ...volunteerHour, date: e ? e.format('YYYY-MM-DD') : '' })
                }
                slotProps={{ textField: { fullWidth: true } }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <HorseHours
              hours={volunteerHour.hours}
              mins={volunteerHour.mins}
              handleChange={(event) => {
                handleHoursChange(event);
              }}
              removeEnd
            />
          </Grid>
        </Grid>
      </form>
    </BasicModal>
  );
}

export default EditVolunteer;
