import { UnformattedDailyUsage } from '../../types/Usage';

import { useEffect, useState } from 'react';

import { HttpClient } from '../../helpers/httpClient';
import DisplaySCUsageRow from './DisplaySCUsageRow';
import { Grid, Typography } from '@mui/material';

const dayString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function DisplaySCUsage(props: {
  selectedDate: string;
  selectedHorse: string;
  setErrorMessage: Function;
}) {
  const [usage, setUsage] = useState<UnformattedDailyUsage[]>([]);

  const { setErrorMessage, selectedHorse, selectedDate } = props;

  function updateUsage(usageId: number, updatedUsage: UnformattedDailyUsage) {
    const nextUsage = usage.map((usage: UnformattedDailyUsage) => {
      if (usageId === usage.id) {
        return updatedUsage;
      }

      return usage;
    });

    setUsage(nextUsage);
  }

  async function getUsage(
    setUsage: Function,
    setErrorMessage: Function,
    selectedDate: string,
    selectedHorse: string
  ) {
    try {
      const result: UnformattedDailyUsage[] = await HttpClient.get(
        `/usage/day?date=${encodeURIComponent(selectedDate)}&horse=${encodeURIComponent(
          selectedHorse
        )}&formatted=false`
      );
      console.log('Result', result);
      setUsage(
        result.filter((usage: UnformattedDailyUsage) => {
          return usage.usageType === 'SC';
        })
      );
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  useEffect(() => {
    getUsage(setUsage, setErrorMessage, selectedDate, selectedHorse);
  }, []);

  if (usage.length <= 0) {
    return null;
  }

  return (
    <Grid marginTop='1em'>
      <Typography variant='h5' fontWeight={'bold'} marginBottom='1em'>
        Camps
      </Typography>
      {usage.map((usage: UnformattedDailyUsage, index: number) => {
        return (
          <DisplaySCUsageRow
            key={usage.id}
            usage={usage}
            setErrorMessage={setErrorMessage}
            handleSubmit={() => {
              getUsage(setUsage, setErrorMessage, selectedDate, selectedHorse);
            }}
            updateUsage={updateUsage}
          />
        );
      })}

      <Typography variant='caption' color={'grey'}>
        To add this usage for this horse please go to the Add Usage page
      </Typography>
    </Grid>
  );
}

export default DisplaySCUsage;
