import { Grid, MenuItem, TextField, Typography } from '@mui/material';

function EditAddVolunteerInfo(props: { level: number | string; setLevel: Function }) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            select
            label='Volunteer Level'
            aria-label='Volunteer Level'
            onChange={(e) => {
              props.setLevel(Number(e.target.value));
            }}
            value={props.level}
            name='volunteerLevel'
            fullWidth
          >
            <MenuItem value='1'>1</MenuItem>
            <MenuItem value='2'>2</MenuItem>
            <MenuItem value='3'>3</MenuItem>
          </TextField>
        </Grid>
      </Grid>
    </>
  );
}

export default EditAddVolunteerInfo;
