import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from 'react';

function Redirect() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/board/${dayjs().format('YYYY-MM-DD')}`);
  }, [navigate]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </Box>
  );
}

export default Redirect;
