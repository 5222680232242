import { ChangeEvent, useState } from 'react';
import { AffectedPart, HorseLameness } from '../../types/Horses';
import ErrorBanner from '../ErrorBanner';
import { HttpClient } from '../../helpers/httpClient';
import LimitedTextArea from '../LimitedTextArea';
import BasicModal from './BasicModal';
import { Box, Button, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

function AddLamenessModal(props: {
  horse: string;
  horseName: string;
  lameness: HorseLameness | undefined;
  show: boolean;
  handleClose: () => void;
  handleConfirm: Function;
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [lameness, setLameness] = useState<HorseLameness>(
    props.lameness
      ? props.lameness
      : {
          horse: props.horse,
          horseName: props.horseName,
          affectedPart: AffectedPart.None,
          notes: '',
          startDate: '',
          checkDate: '',
        }
  );

  async function handleSubmit() {
    setErrorMessage('');

    const form: any = document.getElementById('lamenessForm');

    try {
      if (form && form.checkValidity()) {
        if (lameness.notes && lameness.notes.length > 250) {
          setErrorMessage('Comments section is too long. Please check and reduce the size.');
          return;
        }

        if (lameness.affectedPart == AffectedPart.None) {
          setErrorMessage('Please select an affected part.');
          return;
        }

        const result = await HttpClient.post(`/horses/${props.horse}/lameness`, lameness);
        console.log(result);

        props.handleConfirm();
        props.handleClose();
      }
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    let newValue: string | boolean | number = e.target.type === 'checkbox' ? checked : value;

    setLameness((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  return (
    <BasicModal
      onClose={props.handleClose}
      open={props.show}
      title='Add Lameness'
      submitButton={{
        color: 'success',
        text: props.lameness ? 'Update' : 'Add',
        onClick: async (event) => {
          await handleSubmit();
        },
      }}
      closeButton
    >
      <ErrorBanner errorHeading='Error adding lameness' errorMessage={errorMessage} />
      <form id='lamenessForm'>
        <Box marginBottom={'1em'}>
          <TextField
            label='Affected Part'
            select
            required
            aria-label='Affected Part'
            onChange={handleInputChange}
            value={lameness.affectedPart}
            name='affectedPart'
            disabled={props.lameness !== undefined}
            error={!lameness.affectedPart}
            fullWidth
          >
            <MenuItem value={AffectedPart.FrontLeft}>Front Left</MenuItem>
            <MenuItem value={AffectedPart.FrontRight}>Front Right</MenuItem>
            <MenuItem value={AffectedPart.BackLeft}>Back Left</MenuItem>
            <MenuItem value={AffectedPart.BackRight}>Back Right</MenuItem>
            <MenuItem value={AffectedPart.MultipleLegs}>Multiple Legs</MenuItem>
            <MenuItem value={AffectedPart.Other}>Other</MenuItem>
            <MenuItem value={AffectedPart.Unknown}>Unknown</MenuItem>
          </TextField>
        </Box>
        <Box marginBottom={'1em'}>
          <TextField
            label='Start Date'
            required
            type='date'
            onChange={handleInputChange}
            value={lameness.startDate}
            name='startDate'
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            error={!lameness.startDate}
          />
        </Box>
        <Box marginBottom={'1em'}>
          <TextField
            label='Recheck Date'
            type='date'
            onChange={handleInputChange}
            value={lameness.checkDate}
            name='checkDate'
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box marginBottom={'1em'}>
          <LimitedTextArea
            maxChars={250}
            label='Comments'
            data={lameness.notes ? lameness.notes : ''}
            setData={(e: ChangeEvent<HTMLInputElement>) => {
              setLameness((prevState) => ({
                ...prevState,
                notes: e.target.value,
              }));
            }}
          />
        </Box>
      </form>
    </BasicModal>
  );
}

export default AddLamenessModal;
