import { useEffect } from 'react';

import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useNavigate, useLocation } from 'react-router';
import { Grid, Typography } from '@mui/material';

export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/';

  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  return (
    <div style={{ height: '90vh', overflowY: 'scroll' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Login
        </Typography>
      </Grid>
      <div className='d-flex align-items-center justify-content-center'>
        <View className='auth-wrapper'>
          <Authenticator hideSignUp={true}></Authenticator>
        </View>
      </div>
    </div>
  );
}
