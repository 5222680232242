import { ChangeEvent, ChangeEventHandler, MouseEventHandler, useState } from 'react';
import { FeedTime, FeedType, HorseFeed } from '../../types/Horses';
import ErrorBanner from '../ErrorBanner';
import { HttpClient } from '../../helpers/httpClient';
import { Box, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import BasicModal from '../Modals/BasicModal';
import dayjs from 'dayjs';
import { PersonToAdd } from '../../types/People';
import { useNavigate } from 'react-router-dom';

function BulkDuplicateCreationModal(props: {
  ridersToAdd: (PersonToAdd & {
    createNew?: boolean;
    duplicate: boolean;
    lessonInfo: {
      currentLessons: [
        {
          lessonDay?: string;
          lessonId?: number;
        }
      ];
    };
  })[];
  setRidersToAdd: Function;
  show: boolean;
  handleClose: () => void;
  handleConfirm: Function;
}) {
  return (
    <BasicModal
      open={props.show}
      onClose={props.handleClose}
      title='Duplicate Riders'
      submitButton={{
        color: 'success',
        text: 'Proceed with creation',
        onClick: async (event) => {
          await props.handleConfirm();
        },
      }}
      closeButton
    >
      <Box>
        <Typography marginBottom={'0.5em'}>
          The following riders already exist in the system. Please select what you would like to do.
          If the contact info has updated for one of these riders please go to their page to update
          it.
        </Typography>
        <Grid container spacing={2} marginBottom={'0.5em'}>
          <Grid item xs={12} sm={6} fontWeight={'bold'}>
            Name
          </Grid>
          <Grid item xs={12} sm={6} fontWeight={'bold'}>
            What would you like to do?
          </Grid>
        </Grid>
        {props.ridersToAdd.map((rider, index) => {
          if (!rider.duplicate) {
            return null;
          }

          return (
            <Grid container spacing={2} key={index} marginBottom={'0.5em'}>
              <Grid item xs={12} sm={6}>
                {rider.firstName} {rider.lastName}
              </Grid>
              <Grid item xs={12} sm={6}>
                <ToggleButtonGroup
                  value={rider.createNew ? 'createNew' : 'addLesson'}
                  exclusive
                  onChange={(
                    event: React.MouseEvent<HTMLElement>,
                    newFilter: 'addLesson' | 'createNew' | null
                  ) => {
                    const newRidersToAdd = [...props.ridersToAdd];
                    newRidersToAdd[index] = {
                      ...newRidersToAdd[index],
                      createNew: newFilter === 'createNew',
                    };
                    props.setRidersToAdd(newRidersToAdd);
                  }}
                  aria-label='report type'
                  fullWidth
                  color='success'
                >
                  <ToggleButton value='addLesson'>Add Lesson</ToggleButton>
                  <ToggleButton value='createNew'>Create New Rider</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </BasicModal>
  );
}

export default BulkDuplicateCreationModal;
