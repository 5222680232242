import { ChangeEvent, useEffect, useState } from 'react';

import {
  Box,
  Grid,
  MenuItem,
  TextField,
  Typography,
  createTheme,
  useMediaQuery,
} from '@mui/material';
import dayjs from 'dayjs';
import CheckIcon from '@mui/icons-material/Check';
import { Day } from './DayView';
import { formatTime } from '../../helpers/utils';

const dayString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const theme = createTheme();

export function concatIfBothNotEmpty(str1: string, str2: string) {
  if (str1.trim() !== '' && str2.trim() !== '') {
    return str1 + ', ' + str2;
  } else if (str1) {
    return str1;
  }

  return str2;
}

function DayViewColumn(props: { lessons?: Day }) {
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Box>
      {props.lessons?.map((lesson, index1) => {
        return (
          <Grid key={lesson.lessonTime} padding={'0.25em'} rowSpacing={'0.5em'}>
            <Box sx={{ backgroundColor: '#588157', borderRadius: '0.25em' }}>
              <Grid container>
                <Grid item xs={12} padding={'0.25em'} paddingLeft={'0.5em'}>
                  <Typography
                    variant='h4'
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    {formatTime(lesson.lessonTime)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                {lesson.entries.map((entry, index2) => {
                  return (
                    <Grid
                      container
                      key={index2}
                      padding={'0.25em'}
                      paddingLeft={'0.5em'}
                      sx={{
                        backgroundColor: index2 % 2 === 0 ? '#a3b18a' : '#dad7cd',
                        borderRadius:
                          index2 === 0
                            ? '0.25em 0.25em 0em 0em'
                            : index2 === lesson.entries.length - 1
                            ? '0em 0em 0.25em 0.25em'
                            : undefined,
                      }}
                    >
                      <Grid item xs={6}>
                        <Typography variant='h5'>{entry.horseName}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='h5'>{entry.personName}</Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        );
      })}
    </Box>
  );
}

export default DayViewColumn;
