import { ReactComponent as SaddleImg } from './saddle.svg';

export default function Saddle() {
  return (
    <SaddleImg height={25} width={25} style={{ paddingBottom: '3.5px', paddingRight: '3.5px' }} />
  );
}

export function AdjustableSaddle() {
  return <SaddleImg height={40} width={40} />;
}
