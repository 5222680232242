import { Horse } from '../../types/Horses';

import { ChangeEvent, useState } from 'react';
import { HttpClient } from '../../helpers/httpClient';
import { useNavigate } from 'react-router-dom';
import { Usage } from '../../types/Usage';
import HorseSelect from '../HorseSelect';
import { SubmitButton } from '../SubmitButton';
import { Person } from '../../types/People';
import dayjs from 'dayjs';
import { Autocomplete, Grid, InputAdornment, TextField } from '@mui/material';

function AddIndividualUsage(props: {
  riders: Person[];
  horses: Horse[];
  usageType: string;
  setErrorMessage: Function;
  date: dayjs.Dayjs;
  includeRider?: boolean;
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const [rider, setRider] = useState({
    id: '',
    name: '',
    firstName: '',
    lastName: '',
  });

  const [horse, setHorse] = useState('');
  const [duration, setDuration] = useState({ mins: '', hours: '' });

  function handleHoursChange(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const newDuration = {
      ...duration,
    };

    if (name === 'hours') {
      newDuration.hours = e.target.value;
    } else {
      newDuration.mins = e.target.value;
    }

    setDuration(newDuration);
  }

  async function handleSubmit() {
    setLoading(true);
    if (props.includeRider && !rider.id) {
      props.setErrorMessage('Please select a rider');
      setLoading(false);
      return;
    }

    if (!horse) {
      props.setErrorMessage('Please select a horse');
      setLoading(false);
      return;
    }

    if (!duration || isNaN(Number(duration.hours)) || isNaN(Number(duration.mins))) {
      props.setErrorMessage('Hours and mins must be a number');
      setLoading(false);
      return;
    }

    if (60 * Number(duration.hours) + Number(duration.mins) <= 0) {
      props.setErrorMessage('Duration must be greater than 0');
      setLoading(false);
      return;
    }

    let usage: Usage[] = [
      {
        usageType: props.usageType,
        horse,
        rider: props.includeRider ? rider.id : undefined,
        duration: 60 * Number(duration.hours) + Number(duration.mins),
        usageDate: props.date.format('YYYY-MM-DD'),
      },
    ];

    try {
      const result = await HttpClient.post('/usage', usage);
      console.log(result);
      navigate(`/usage/${props.date.format('YYYY-MM-DD')}`);
    } catch (error) {
      props.setErrorMessage((error as Error).message);
    }

    setLoading(false);
  }

  return (
    <div>
      {props.includeRider ? (
        <Grid container marginBottom='1em'>
          (
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option.name}
              getOptionKey={(option) => option.id}
              value={rider.id ? rider : null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={props.riders.map((rider) => {
                return {
                  id: rider.id,
                  name: rider.firstName + ' ' + rider.lastName,
                  firstName: rider.firstName,
                  lastName: rider.lastName,
                };
              })}
              renderInput={(params) => <TextField {...params} label='Rider' />}
              onChange={(event, selectedOption) => {
                setRider(
                  selectedOption
                    ? selectedOption
                    : {
                        id: '',
                        name: '',
                        firstName: '',
                        lastName: '',
                      }
                );
              }}
            />
          </Grid>
          )
        </Grid>
      ) : null}
      <Grid container marginBottom='1em'>
        <Grid item xs={12}>
          <HorseSelect
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setHorse(e.target.value);
            }}
            horses={props.horses}
          />
        </Grid>
      </Grid>
      <Grid container marginBottom='1em' spacing={2}>
        <Grid item xs={6}>
          <TextField
            variant='outlined'
            label='Hours'
            type='tel'
            name='hours'
            onChange={handleHoursChange}
            value={duration.hours}
            InputProps={{
              endAdornment: <InputAdornment position='end'>hours</InputAdornment>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant='outlined'
            label='Mins'
            type='tel'
            aria-label='mins'
            name='mins'
            onChange={handleHoursChange}
            value={duration.mins}
            InputProps={{
              endAdornment: <InputAdornment position='end'>mins</InputAdornment>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            required
          />
        </Grid>
      </Grid>

      <SubmitButton handleSubmit={handleSubmit} disabled={loading} />
    </div>
  );
}

export default AddIndividualUsage;
