import { Box, MenuItem, TextField } from '@mui/material';
import { FeedTime, FeedType, HorseFeed } from '../../types/Horses';
import { ChangeEventHandler } from 'react';

function OtherFeed(props: {
  feed: HorseFeed;
  handleInputChange: ChangeEventHandler;
  update: boolean;
}) {
  return (
    <>
      <TextField
        select // Use select for dropdown
        label='Feed Type'
        variant='outlined'
        value={props.feed.feedType}
        name='feedType'
        disabled={props.update}
        onChange={props.handleInputChange}
        required
        fullWidth
        margin='normal'
      >
        <MenuItem value={FeedType.BeetPulp}>Beet Pulp</MenuItem>
        <MenuItem value={FeedType.Cubes}>Cubes</MenuItem>
        <MenuItem value={FeedType.Meds}>Meds</MenuItem>
        <MenuItem value={FeedType.Grain}>Grain</MenuItem>
        <MenuItem value={FeedType.Supplements}>Supplements</MenuItem>
        <MenuItem value={FeedType.Other}>Other</MenuItem>
      </TextField>
      {props.feed.feedType === FeedType.Other ||
      props.feed.feedType === FeedType.Grain ||
      props.feed.feedType === FeedType.Meds ||
      props.feed.feedType === FeedType.Supplements ? (
        <Box marginBottom={'1em'}>
          <TextField
            label={
              props.feed.feedType === FeedType.Other ? 'Feed Name' : `${props.feed.feedType} Name`
            }
            required
            type='text'
            placeholder=''
            name='subType'
            onChange={props.handleInputChange}
            value={props.feed.subType}
            fullWidth
          />
        </Box>
      ) : null}
      <Box marginBottom={'1em'}>
        <TextField
          label='Amount'
          required
          type='text'
          placeholder='Amount'
          name='amount'
          onChange={props.handleInputChange}
          value={props.feed.amount}
          fullWidth
        />
      </Box>
      <Box marginBottom={'1em'}>
        <TextField
          select
          required
          label='Feed Time'
          onChange={props.handleInputChange}
          value={props.feed.feedTime}
          name='feedTime'
          fullWidth
        >
          <MenuItem value={FeedTime.AM}>AM</MenuItem>
          <MenuItem value={FeedTime.PM}>PM</MenuItem>
          <MenuItem value={FeedTime.Noon}>Noon</MenuItem>
          <MenuItem value={FeedTime.Night}>Night</MenuItem>
        </TextField>
      </Box>
      <Box marginBottom={'1em'}>
        <TextField
          label='Details/Notes'
          type='text'
          placeholder=''
          name='details'
          onChange={props.handleInputChange}
          value={props.feed.details}
          fullWidth
        />
      </Box>
    </>
  );
}

export default OtherFeed;
