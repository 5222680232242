import { Horse } from '../types/Horses';
import { LessonModifications } from '../types/Lessons';
import { Person } from '../types/People';
import AddFreeLunges from './Usage/AddFreeLunges';
import AddHorsemanshipUsage from './Usage/AddHorsemanshipUsage';
import AddIndividualUsage from './Usage/AddIndividualUsage';
import AddHorseTableUsage from './Usage/AddHorseTableUsage';
import dayjs from 'dayjs';
import AddHackRideOrPrivateUsage from './Usage/AddHackRideOrPrivateUsage';

function UsageType(props: {
  type: string;
  riders: Person[];
  horses: Horse[];
  modifications: LessonModifications[];
  setErrorMessage: Function;
  date: dayjs.Dayjs;
}) {
  if (props.type === 'G') {
    return (
      <div>
        <AddHorsemanshipUsage
          modifications={props.modifications}
          riders={props.riders}
          horses={props.horses}
          setErrorMessage={props.setErrorMessage}
          date={props.date}
        />
      </div>
    );
  } else if (props.type === 'FL') {
    return (
      <AddFreeLunges
        horses={props.horses}
        setErrorMessage={props.setErrorMessage}
        date={props.date}
      />
    );
  } else if (
    props.type === 'UM' ||
    props.type === 'BP' ||
    props.type === 'LB' ||
    props.type === 'SC'
  ) {
    return (
      <AddHorseTableUsage
        horses={props.horses}
        setErrorMessage={props.setErrorMessage}
        date={props.date}
        type={props.type}
      />
    );
  } else if (props.type === 'P' || props.type === 'HR') {
    return (
      <AddHackRideOrPrivateUsage
        usageType={props.type}
        riders={props.riders}
        horses={props.horses}
        setErrorMessage={props.setErrorMessage}
        date={props.date}
      />
    );
  } else if (
    props.type === 'S' ||
    props.type === 'P' ||
    props.type === 'LR' ||
    props.type === 'BR'
  ) {
    return (
      <AddIndividualUsage
        usageType={props.type}
        riders={props.riders}
        horses={props.horses}
        setErrorMessage={props.setErrorMessage}
        date={props.date}
        includeRider
      />
    );
  } else if (props.type === 'L') {
    return (
      <AddIndividualUsage
        usageType={props.type}
        riders={props.riders}
        horses={props.horses}
        setErrorMessage={props.setErrorMessage}
        date={props.date}
      />
    );
  }

  return null;
}

export default UsageType;
