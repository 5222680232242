import { useAuthenticator } from '@aws-amplify/ui-react';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Home() {
  const { route, signOut } = useAuthenticator((context) => [context.route]);
  const navigate = useNavigate();

  function logOut() {
    signOut();
    navigate('/login');
  }

  if (route === 'authenticated') {
    return (
      <Grid container justifyContent='center' alignItems='center' style={{ height: '100vh' }}>
        <Grid item>
          <Button
            variant='contained'
            color='success'
            style={{ height: '40vh', width: '50vw', fontSize: 'xxx-large' }}
            onClick={() => {
              navigate('/usage/new');
            }}
          >
            Enter Usage
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent='center' alignItems='center' style={{ height: '100vh' }}>
      <Grid item>
        <Button
          variant='contained'
          color='success'
          style={{ height: '40vh', width: '50vw', fontSize: 'xxx-large' }}
          onClick={() => {
            navigate('/login');
          }}
        >
          Login
        </Button>
      </Grid>
    </Grid>
  );
}

export default Home;
