import { ChangeEvent } from 'react';
import { Grid, InputAdornment, TextField } from '@mui/material';

type KeyValue = {
  [key: string]: { mins: string; hours: string };
};

function HorseHours(props: {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  hours: string;
  mins: string;
  removeEnd?: boolean;
}) {
  return (
    <Grid container columnSpacing={0.5} paddingLeft='0em'>
      <Grid item alignContent={'center'} justifyContent={'center'} xs={6}>
        <TextField
          variant='outlined'
          label='Hours'
          type='tel'
          name='hours'
          onChange={props.handleChange}
          value={props.hours}
          InputProps={
            !props.removeEnd
              ? {
                  endAdornment: <InputAdornment position='end'>hours</InputAdornment>,
                }
              : {}
          }
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Grid>
      <Grid item alignContent={'center'} justifyContent={'center'} xs={6}>
        <TextField
          variant='outlined'
          label='Mins'
          type='tel'
          aria-label='mins'
          name='mins'
          onChange={props.handleChange}
          value={props.mins}
          InputProps={
            !props.removeEnd
              ? {
                  endAdornment: <InputAdornment position='end'>hours</InputAdornment>,
                }
              : {}
          }
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

export default HorseHours;
