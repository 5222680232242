import { Grid, Typography } from '@mui/material';
import { RiderAndLesson } from '../../types/People';

function DisplayPerson(props: { person: RiderAndLesson }) {
  const { person } = props;

  return (
    <>
      <Grid container marginTop='1em' rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography fontWeight={'bold'}>First Name</Typography>
          <Typography>{person.firstName}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography fontWeight={'bold'}>Last Name</Typography>
          <Typography>{person.lastName}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography fontWeight={'bold'}>Start/Eval Date</Typography>
          <Typography>{person.startDate}</Typography>
        </Grid>
      </Grid>
      {person.rider ? (
        <Grid container marginTop='0.5em' rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography fontWeight={'bold'}>Staff</Typography>
            <Typography>{person.staff ? 'Yes' : 'No'}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography fontWeight={'bold'}>Schooling rider</Typography>
            <Typography>{person.schooling ? 'Yes' : 'No'}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography fontWeight={'bold'}>Eval rider</Typography>
            <Typography>{person.eval ? 'Yes' : 'No'}</Typography>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

export default DisplayPerson;
