import { formatTime } from '../../helpers/utils';
import { UnformattedDailyUsage, UsageTypeAndDescription } from '../../types/Usage';
import { ChangeEvent, useState } from 'react';
import { HttpClient } from '../../helpers/httpClient';
import ConfirmDeletionModal from '../Modals/ConfirmDeletionModal';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import HorseHours from './HorseHours';

function DisplaySCUsageRow(props: {
  usage: UnformattedDailyUsage;
  setErrorMessage: Function;
  updateUsage: Function;
  handleSubmit: Function;
}) {
  const { usage, setErrorMessage } = props;
  const [mode, setMode] = useState<'view' | 'edit' | 'delete'>(props.usage.id ? 'view' : 'edit');
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);
  const [duration, setDuration] = useState({
    hours: `${Math.floor(usage.duration / 60)}`,
    mins: `${usage.duration % 60}`,
  });
  const [lesson, setLesson] = useState(`${usage.lesson}`);

  function handleDurationUsageChange(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;

    const updated = {
      ...duration,
    };

    if (name === 'hours') {
      updated.hours = e.target.value;
    } else {
      updated.mins = e.target.value;
    }

    setDuration(updated);
  }

  async function handleSubmit() {
    setButtonsDisabled(true);
    setErrorMessage('');
    if (isNaN(Number(duration.hours)) || isNaN(Number(duration.mins))) {
      setErrorMessage('Hours and mins must be a number');
      setButtonsDisabled(false);
      return;
    }

    if (Number(duration.hours) < 0 || Number(duration.mins) < 0) {
      setErrorMessage('Hours and mins cannot be negative');
      setButtonsDisabled(false);
      return;
    }

    if (60 * Number(duration.hours) + Number(duration.mins) <= 0) {
      setErrorMessage('Usage duration must be greater than 0');
      setButtonsDisabled(false);
      return;
    }

    if (!lesson) {
      setErrorMessage('You must select a lesson');
      setButtonsDisabled(false);
      return;
    }

    try {
      const result = await HttpClient.put(`/usage/${usage.id}`, {
        ...usage,
        duration: 60 * Number(duration.hours) + Number(duration.mins),
        lesson: Number(lesson),
      });
      console.log(result);
      await props.handleSubmit();
      setMode('view');
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
    setButtonsDisabled(false);
  }

  async function handleDelete() {
    setButtonsDisabled(true);
    setErrorMessage('');
    try {
      const result = await HttpClient.delete(`/usage/${usage.id}`);
      console.log(result);
      await props.handleSubmit();
      setMode('view');
    } catch (error) {
      setErrorMessage((error as Error).message);
    }

    setButtonsDisabled(false);
  }

  if (mode === 'edit') {
    return (
      <Grid container key={usage.id} id={`${usage.id}`} marginBottom={'1em'} spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            label='Time'
            select
            required
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setLesson(e.target.value);
            }}
            value={lesson}
            name='lesson'
            fullWidth
          >
            {/* TODO: Make this not hard coded */}
            <MenuItem value='12'>AM</MenuItem>
            <MenuItem value='13'>PM</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <HorseHours
            hours={duration.hours}
            mins={duration.mins}
            handleChange={handleDurationUsageChange}
            removeEnd
          />
        </Grid>
        <Grid item xs={12} sm={4} alignContent={'center'}>
          <Button
            variant='contained'
            color='success'
            onClick={handleSubmit}
            disabled={buttonsDisabled}
            fullWidth
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <ConfirmDeletionModal
        show={mode === 'delete'}
        handleClose={() => setMode('view')}
        handleConfirm={handleDelete}
      />
      <Grid container key={usage.id} id={`${usage.id}`} marginBottom={'1em'} spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography fontWeight={'bold'}>Time</Typography>
          <Typography>{formatTime(usage.lessonTime) === '9:00 AM' ? 'AM' : 'PM'}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography fontWeight={'bold'}>Duration</Typography>
          <Typography>
            {duration.hours} hours, {duration.mins} mins
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={4} spacing={1}>
          <Grid item xs={12} sm={6}>
            <Button
              variant='contained'
              color='success'
              id={`delete-${usage.id}`}
              onClick={() => setMode('edit')}
              style={{ marginRight: '0.5em' }}
              disabled={buttonsDisabled}
              fullWidth
            >
              <EditIcon style={{ width: '20', paddingBottom: '3.5px' }} />
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant='contained'
              color='error'
              id={`delete-${usage.id}`}
              onClick={() => setMode('delete')}
              disabled={buttonsDisabled}
              fullWidth
            >
              <DeleteIcon style={{ width: '20', paddingBottom: '3.5px' }} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default DisplaySCUsageRow;
