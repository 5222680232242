import HorseHead from '../../images/HorseHead';
import Saddle from '../../images/Saddle';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import DataUsageSharpIcon from '@mui/icons-material/DataUsageSharp';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MenuIcon from '@mui/icons-material/Menu';
import SchoolIcon from '@mui/icons-material/School';
import LogoutIcon from '@mui/icons-material/Logout';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { dateToStringLocal } from '../../helpers/utils';
import { useState } from 'react';
import { Avatar } from '@mui/material';

export function SideNav(props: { isMobile: boolean }) {
  const { isMobile } = props;
  const { signOut } = useAuthenticator((context) => [context.route, context.signOut]);
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState<boolean>(isMobile ? true : false);

  function logOut() {
    signOut();
    navigate('/login');
  }

  function autoCollapseOnMobile() {
    if (isMobile) {
      setCollapsed(true);
    }
  }

  return (
    <Sidebar
      width={props.isMobile ? '101vw' : '20vw'}
      backgroundColor='rgb(46, 125, 50, 0.6)'
      collapsed={collapsed}
    >
      <Menu
        menuItemStyles={{
          button: {
            // the active class will be added automatically by react router
            // so we can use it to style the active menu item
            [`&.active`]: {
              backgroundColor: 'lightgrey',
              color: '#b6c8d9',
            },
          },
        }}
      >
        <MenuItem
          icon={<MenuIcon />}
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          {!props.isMobile ? (
            <Avatar
              src='/images/logo.webp'
              variant='square'
              sx={{ width: '100px', height: '30px' }}
            />
          ) : (
            'WELCA Tracking Site'
          )}
        </MenuItem>
        <MenuItem
          onClick={autoCollapseOnMobile}
          component={<Link to='/' />}
          icon={<HomeOutlinedIcon />}
        >
          Home
        </MenuItem>
        <MenuItem
          onClick={autoCollapseOnMobile}
          component={<Link to={`/usage/${dateToStringLocal(new Date())}`} />}
          icon={<DataUsageSharpIcon />}
        >
          Usage
        </MenuItem>
        <MenuItem
          onClick={autoCollapseOnMobile}
          component={<Link to={`/board/${dateToStringLocal(new Date())}`} />}
          icon={<ContentPasteIcon />}
        >
          Board
        </MenuItem>
        <MenuItem
          onClick={autoCollapseOnMobile}
          component={<Link to='/horses' />}
          icon={<HorseHead />}
        >
          Horses
        </MenuItem>
        <MenuItem
          onClick={autoCollapseOnMobile}
          component={<Link to='/horses/saddles' />}
          icon={<Saddle />}
        >
          Saddles
        </MenuItem>
        <MenuItem
          onClick={autoCollapseOnMobile}
          component={<Link to='/people' />}
          icon={<PeopleOutlinedIcon />}
        >
          People
        </MenuItem>
        <MenuItem
          onClick={autoCollapseOnMobile}
          component={<Link to='/volunteers/hours' />}
          icon={<HourglassEmptyIcon />}
        >
          Volunteer Hours
        </MenuItem>
        <MenuItem
          onClick={autoCollapseOnMobile}
          component={<Link to='/lessons' />}
          icon={<SchoolIcon />}
        >
          Lessons
        </MenuItem>
        <MenuItem
          onClick={autoCollapseOnMobile}
          component={<Link to='/reports' />}
          icon={<AssessmentIcon />}
        >
          Reports
        </MenuItem>
        <MenuItem onClick={logOut} icon={<LogoutIcon />}>
          Log Out
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}
