import { useEffect, useState } from 'react';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorBanner from '../../components/ErrorBanner';
import { HttpClient } from '../../helpers/httpClient';
import { DailyUsage, WeeklyUsage } from '../../types/Usage';
import WeeklyUsages from '../../components/WeeklyUsage';
import { Horse } from '../../types/Horses';
import DailyUsages from '../../components/Usage/DailyUsage';
import { Lesson } from '../../types/Lessons';
import { Button, Container, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 6,
});

function Usages() {
  const params = useParams();

  const navigate = useNavigate();
  const [radioValue, setRadioValue] = useState<'day' | 'week' | null>('day');
  const [errorMessage, setErrorMessage] = useState('');
  const [weeklyUsage, setWeeklyUsage] = useState<WeeklyUsage>({});
  const [dailyUsage, setDailyUsage] = useState<DailyUsage>({});
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [horses, setHorses] = useState([]);

  function stupidDateGetter(date: string | undefined) {
    if (!date || date.split('-').length != 3) {
      console.log('Invalid date param');
      navigate(`/usage/${dayjs().format('YYYY-MM-DD')}`);
      return dayjs();
    }
    return dayjs(date);
  }

  let currentDate = stupidDateGetter(params.date);
  const daysBack = currentDate.day() !== 6 ? currentDate.day() + 1 : 0;
  currentDate = currentDate.subtract(daysBack, 'day');
  const [date, setDate] = useState<dayjs.Dayjs | null>(
    radioValue === 'day' ? stupidDateGetter(params.date) : currentDate
  );

  const timeFilter = [
    { name: 'Day', value: 'day' },
    { name: 'Week', value: 'week' },
    // { name: 'Month', value: 'month' },
    // { name: 'Year', value: 'year' },
  ];

  const isSaturday = (date: dayjs.Dayjs) => {
    return date.day() === 6;
  };

  const shouldDisableDate = (date: dayjs.Dayjs): boolean => {
    return radioValue === 'week' && !isSaturday(date);
  };

  async function getUsage(
    setUsage: Function,
    setHorses: Function,
    radioValue: string | null,
    date: dayjs.Dayjs | null
  ) {
    setErrorMessage('');

    if (radioValue == null) {
      radioValue = 'day';
    }

    if (date === null) {
      return;
    }

    const dateString = date.format('YYYY-MM-DD');

    try {
      const result: { Usage: WeeklyUsage; Horses: Horse[] } = await HttpClient.get(
        `/usage/${radioValue}?date=${encodeURIComponent(dateString)}`
      );
      console.log(result);
      setUsage(result.Usage);
      setHorses(result.Horses);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  async function getLessons(setLessons: Function) {
    try {
      const result = await HttpClient.get('/lessons');
      setLessons(result);
    } catch (error) {
      setErrorMessage('Could not load available lessons. Please contact Rebecca.');
    }
  }

  useEffect(() => {
    getLessons(setLessons);
    if (radioValue === 'week') {
      getUsage(setWeeklyUsage, setHorses, radioValue, date);
      return;
    }

    getUsage(setDailyUsage, setHorses, radioValue, date);
  }, [radioValue, date]);

  const currentlyLameHorses = horses.filter((horse: Horse) => {
    return horse.currentLameness && horse.currentLameness > 0;
  });

  return (
    <Container style={{ paddingTop: '1em', paddingBottom: '1em' }}>
      <Grid
        container
        spacing={2}
        paddingBottom='1em'
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Grid item xs={12} sm={3}>
          <ToggleButtonGroup
            value={radioValue}
            exclusive
            onChange={(event: React.MouseEvent<HTMLElement>, newFilter: 'week' | 'day' | null) => {
              if (newFilter === 'week') {
                let currentDate = dayjs(date);
                const daysBack = currentDate.day() !== 6 ? currentDate.day() + 1 : 0;
                currentDate = currentDate.subtract(daysBack, 'day');
                setDate(currentDate);
                navigate(`/usage/${currentDate?.format('YYYY-MM-DD')}`);
              }
              setRadioValue(newFilter);
            }}
            aria-label='report type'
            fullWidth
            color='success'
          >
            {timeFilter.map((radio, idx) => (
              <ToggleButton value={radio.value} key={idx}>
                {radio.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={date}
              onChange={(value, context) => {
                setDate(value);
                navigate(`/usage/${value?.format('YYYY-MM-DD')}`);
              }}
              shouldDisableDate={shouldDisableDate}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            fullWidth
            onClick={() =>
              navigate(
                {
                  pathname: '/usage/new',
                },
                {
                  state: {
                    date,
                  },
                }
              )
            }
            variant='contained'
            color='success'
          >
            <AddSharpIcon style={{ width: '20', paddingBottom: '3.5px' }} /> Add New
          </Button>
        </Grid>
      </Grid>
      <ErrorBanner errorHeading='Error getting usage' errorMessage={errorMessage} />
      <Grid container>
        <h4>Currently lame horses</h4>
      </Grid>
      <Grid container marginBottom='1em'>
        <Grid item>
          {currentlyLameHorses && currentlyLameHorses.length > 0
            ? currentlyLameHorses
                .map((horse: Horse) => {
                  return horse.barnName;
                })
                .join(', ')
            : 'None'}
        </Grid>
      </Grid>
      {radioValue === 'week' ? (
        <WeeklyUsages usage={weeklyUsage} horses={horses} />
      ) : (
        <DailyUsages usage={dailyUsage} horses={horses} lessons={lessons} selectedDate={date!} />
      )}
      {/* <div style={{ height: '10vh', overflow: 'auto' }}>
        <Grid container>
          <Grid item>
            Usage Codes: G = Group Lesson, FL = Free Lunge, LB = Little Bits, P = Private, SP =
            Semi-Private, SC = Summer Camp, S = Schooling, LR = Lease Ride, BR = Boarder Ride, UM =
            Unmounted, BP = Birthday Party
          </Grid>
        </Grid>
      </div> */}
    </Container>
  );
}

export default Usages;
