import { Button, Grid } from '@mui/material';
import { MouseEventHandler } from 'react';

export function SubmitButton(props: {
  handleSubmit: MouseEventHandler<HTMLButtonElement>;
  disabled: boolean;
  label?: string;
}) {
  return (
    <Grid container spacing={2} padding={'1em'}>
      <Button
        variant='contained'
        color='success'
        onClick={props.handleSubmit}
        disabled={props.disabled}
        fullWidth
        sx={{ marginLeft: '1em' }}
      >
        {props.label ?? 'Submit'}
      </Button>
    </Grid>
  );
}

export function FormSubmitButton(props: { disabled?: boolean; optOut?: Function }) {
  if (props.optOut) {
    return (
      <Grid container spacing={2} marginTop='1em'>
        <Grid item xs={6}>
          <Button
            variant='contained'
            color='success'
            type='submit'
            disabled={props.disabled}
            fullWidth
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant='contained'
            color='inherit'
            onClick={() => {
              if (props.optOut) {
                props.optOut();
              }
            }}
            disabled={props.disabled}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={2} marginTop='1em'>
      <Grid item xs={12}>
        <Button
          variant='contained'
          color='success'
          type='submit'
          disabled={props.disabled}
          fullWidth
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}
