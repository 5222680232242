import { ChangeEvent } from 'react';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { Contact } from '../../types/People';
import { Box, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import EmergencyContact from './EmergencyContacts';

function AddEmergencyContacts(props: { riderContacts: Contact[]; setRiderContacts: Function }) {
  const handleContactChange = (riderContact: Contact, index: number) => {
    let newRiderContacts = [...props.riderContacts];

    newRiderContacts[index] = {
      ...newRiderContacts[index],
      ...riderContact,
    };

    props.setRiderContacts(newRiderContacts);
  };

  function addContact() {
    props.setRiderContacts([...props.riderContacts, { type: 'EmergencyContact', id: uuidv4() }]);
  }

  return (
    <>
      <Typography variant='h4' marginTop={'0.5em'} marginBottom={'0.5em'}>
        Emergency Contacts
      </Typography>
      {props.riderContacts.map((riderContact, index) => {
        return (
          <EmergencyContact
            riderContact={riderContact}
            setRiderContact={(value: Contact) => {
              handleContactChange(value, index);
            }}
            index={index}
          />
        );
      })}
      <Grid container spacing={2} justifyContent='center' marginTop='0.5em'>
        <Grid item xs={8}>
          <Button variant='contained' color='inherit' onClick={addContact} fullWidth>
            <AddSharpIcon style={{ width: '20', paddingBottom: '3.5px' }} />
            Add Emergency Contact
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default AddEmergencyContacts;
