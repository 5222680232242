import { HttpClient } from '../../helpers/httpClient';
import ErrorBanner from '../../components/ErrorBanner';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Lesson } from '../../types/Lessons';
import { formatTime } from '../../helpers/utils';
import { read, utils } from 'xlsx';
import { SubmitButton } from '../../components/SubmitButton';
import { Person, PersonToAdd, RiderAndLesson } from '../../types/People';
import { Box, Button, Container, Grid, Input, TextField, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import dayjs from 'dayjs';
import BulkDuplicateCreationModal from '../../components/Riders/BulkDuplicateCreationModal';

const expectedColumns =
  'FirstName,LastName,StartDate,LessonDay,LessonTime,EmergencyContactFirstName,EmergencyContactLastName,EmergencyContactRelationship,EmergencyContactPhoneNumber';

function BulkAddRider() {
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [failedRiders, setFailedRiders] = useState('');
  const [successfulRiders, setSuccessfulRiders] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [file, setFile] = useState<File>();
  const [fileTargetValue, setFileTargetValue] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [ridersToAdd, setRidersToAdd] = useState<
    (PersonToAdd & {
      id?: string;
      createNew?: boolean;
      duplicate: boolean;
      lessonInfo: {
        currentLessons: [
          {
            lessonDay?: string;
            lessonId?: number;
          }
        ];
      };
    })[]
  >([]);
  const [people, setPeople] = useState<RiderAndLesson[]>([]);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);

  async function validateAndProcessFile(rows: string[][]) {
    if (!rows[0]) {
      setErrorMessage('Invalid file');
      setDisabled(false);
      setFileTargetValue('');
      return;
    }

    if (rows[0].join(',').replace(/ /g, '') != expectedColumns) {
      setErrorMessage('Invalid file columns, expecting the following headers: ' + expectedColumns);
      setDisabled(false);
      setFileTargetValue('');
      return;
    }

    let missingLessons = [];
    let missingFields = [];
    let ridersToAdd: (PersonToAdd & {
      id?: string;
      duplicate: boolean;
      lessonInfo: {
        currentLessons: [
          {
            lessonDay?: string;
            lessonId?: number;
          }
        ];
      };
    })[] = [];
    let foundDuplicate = false;
    for (let i = 1; i < rows.length; i++) {
      const columns = rows[i];
      if (columns.length < 5) {
        missingFields.push(rows[i].join(', '));
        continue;
      }

      const lesson = lessons.find((value) => {
        return (
          value.lessonDay == columns[3].trim() && formatTime(value.lessonTime) == columns[4].trim()
        );
      });

      if (!lesson) {
        missingLessons.push(`${columns[0]} ${columns[1]}`);
        continue;
      }

      const possibleDuplicate = people.filter((p) => {
        return (
          p.firstName.trim().toLowerCase() === columns[0].trim().toLowerCase() &&
          p.lastName.trim().toLowerCase() === columns[1].trim().toLowerCase()
        );
      });

      if (possibleDuplicate.length > 0) {
        foundDuplicate = true;
      }

      ridersToAdd.push({
        id: possibleDuplicate.length > 0 ? possibleDuplicate[0].id : undefined,
        firstName: columns[0],
        lastName: columns[1],
        startDate: columns[2],
        rider: true,
        staff: false,
        schooling: false,
        eval: false,
        volunteer: false,
        volunteerLevel: 0,
        lessonInfo: {
          currentLessons: [
            {
              lessonDay: lesson.lessonDay,
              lessonId: lesson.id,
            },
          ],
        },
        emergencyContacts:
          columns.length > 5
            ? [
                {
                  firstName: columns[5],
                  lastName: columns[6],
                  relationship: columns[7] as '',
                  phoneNumber: columns[8],
                  type: 'EmergencyContact',
                },
              ]
            : [],
        duplicate: possibleDuplicate.length > 0,
      });
    }

    if (missingLessons.length > 0) {
      setErrorMessage(
        'Could not find lesson for the following riders: ' + missingLessons.join(', ')
      );
      setDisabled(false);
      setFileTargetValue('');
      return;
    }

    if (missingFields.length > 0) {
      setErrorMessage('Missing fields for the following rows: ' + missingFields.join('& '));
      setDisabled(false);
      setFileTargetValue('');
      return;
    }

    setRidersToAdd(ridersToAdd);
    setShowDuplicateModal(foundDuplicate);

    console.log(foundDuplicate, ridersToAdd);

    if (!foundDuplicate) {
      await submitRiders(ridersToAdd);
    }
  }

  async function submitRiders(
    add?: (PersonToAdd & {
      id?: string;
      createNew?: boolean;
      duplicate: boolean;
      lessonInfo: {
        currentLessons: [
          {
            lessonDay?: string;
            lessonId?: number;
          }
        ];
      };
    })[]
  ) {
    const failedRiders: string[] = [];
    const succeededRiders: string[] = [];
    const riders = add ? add : ridersToAdd;

    console.log(riders);
    Promise.allSettled(
      riders.map((rider) => {
        if (!rider.duplicate || (rider.duplicate && rider.createNew)) {
          return HttpClient.post('/people', rider);
        }

        return HttpClient.post(
          `/riders/${rider.id}/lessons/${rider.lessonInfo.currentLessons[0].lessonId}`,
          {}
        );
      })
    ).then((result) => {
      for (let i = 0; i < result.length; i++) {
        if (result[i].status == 'rejected') {
          failedRiders.push(`${riders[i].firstName} ${riders[i].lastName}`);
        } else {
          succeededRiders.push(`${riders[i].firstName} ${riders[i].lastName}`);
        }
      }

      setFailedRiders(failedRiders.join(', '));
      setSuccessfulRiders(succeededRiders.join(', '));
      setDisabled(false);
      setFileTargetValue('');
      setFile(undefined);
    });
  }

  function processCSV(file: File) {
    const reader = new FileReader();
    reader.onload = async () => {
      if (reader.result) {
        const lines = reader.result.toString().split('\n');
        validateAndProcessFile(
          lines.map((line: string) =>
            line.split(',').map(function (item: string) {
              return item.trim();
            })
          )
        );
      }
    };
    reader.readAsText(file);
  }

  const handleSubmit = async (event: any) => {
    setDisabled(true);
    if (!file) {
      setErrorMessage('Please select a file.');
      setDisabled(false);
      return;
    }

    setErrorMessage('');
    var isCsv = /(\.csv)$/i;
    if (isCsv.exec(fileTargetValue)) {
      processCSV(file);
      return;
    }

    const data = await file.arrayBuffer();
    const workbook = read(data, { cellDates: false });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = utils.sheet_to_json(worksheet, {
      raw: false,
      header: 1,
      defval: '',
    });
    await validateAndProcessFile(jsonData as string[][]);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
    }

    setFileTargetValue(e.target.value);
  };

  async function getLessons(setLessons: Function) {
    try {
      const result = await HttpClient.get(
        '/lessons',
        {},
        {
          date,
        }
      );
      setLessons(result);
    } catch (error) {
      setErrorMessage('Could not load available lessons. Please contact Rebecca.');
    }
  }

  const getPeople = async () => {
    try {
      const result = (await HttpClient.get('/people')) as RiderAndLesson[];
      setPeople(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  };

  useEffect(() => {
    getLessons(setLessons);
    getPeople();
  }, [date]);

  return (
    <Container style={{ paddingTop: '1em', paddingBottom: '1em' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Bulk Add Riders
        </Typography>
      </Grid>
      <BulkDuplicateCreationModal
        ridersToAdd={ridersToAdd}
        setRidersToAdd={setRidersToAdd}
        show={showDuplicateModal}
        handleClose={() => {
          setDisabled(false);
          setShowDuplicateModal(false);
        }}
        handleConfirm={() => {
          submitRiders();
          setDisabled(false);
          setShowDuplicateModal(false);
        }}
      />
      <ErrorBanner errorHeading='Error adding riders' errorMessage={errorMessage} />
      <h4>File format example. Emergency contact info is optional </h4>
      <p>
        {expectedColumns}
        <br />
        Rebecca, Test, 2024-04-10, Saturday, 7:00 AM, Test, Contact, Parent, 1234567890
        <br />
        Heather, Test, 2024-04-10, Saturday, 9:00 PM
      </p>

      <Grid item xs={12} marginBottom='0.5em' marginTop='0.5em'>
        <TextField
          label='Lesson Start Date'
          type='date'
          value={date}
          name='startDate'
          onChange={(event) => {
            setDate(event.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          helperText='If you have riders on multiple days, select the start date of the latest starting lesson.'
        />
      </Grid>
      <Button
        variant='contained'
        startIcon={<CloudUploadIcon />}
        onClick={() => {
          if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click();
          }
        }}
        sx={{ marginRight: '0.5em', marginBottom: '1em' }}
      >
        Upload
      </Button>
      {file ? file.name : 'No file selected'}
      <input
        ref={fileInputRef}
        type='file'
        onChange={handleFileChange}
        style={{ display: 'none' }}
        accept='.xlsx,.csv'
        value={fileTargetValue}
      />

      {failedRiders ? (
        <>
          <h4>
            Failed to add the following riders. Please check that check formatting and try again.
          </h4>
          <p>{failedRiders}</p>
        </>
      ) : null}
      {successfulRiders ? (
        <>
          <h4>Successfully added the following riders:</h4>
          <p>{successfulRiders}</p>
        </>
      ) : null}
      <SubmitButton handleSubmit={handleSubmit} disabled={disabled} />
    </Container>
  );
}

export default BulkAddRider;
