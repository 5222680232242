import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HttpClient } from '../../helpers/httpClient';
import { Horse, horseType, owners } from '../../types/Horses';
import ErrorBanner from '../../components/ErrorBanner';
import { FormSubmitButton } from '../../components/SubmitButton';
import { Container, Grid, MenuItem, TextField, Typography } from '@mui/material';

function AddHorse() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [horse, setHorse] = useState<Horse>({
    barnName: '',
    showName: '',
    birthDate: '',
    owner: '',
    startDate: '',
    type: '',
    hasPhoto: false,
    dayOff: '',
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    let newValue: string | boolean | number = e.target.type === 'checkbox' ? checked : value;

    if (name === 'type') {
      newValue = Number(value);
    }

    setHorse((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setErrorMessage('');

    try {
      if (form.checkValidity()) {
        const result = await HttpClient.post('/horses', horse);
        navigate('/horses');
      }
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  };

  return (
    <Container style={{ paddingTop: '1em' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Horses
        </Typography>
      </Grid>
      <ErrorBanner errorHeading='Error adding horse' errorMessage={errorMessage} />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              label='Name'
              required
              placeholder='Name'
              name='barnName'
              onChange={handleInputChange}
              value={horse.barnName}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label='Registered Name'
              placeholder='Registered Name'
              name='showName'
              onChange={handleInputChange}
              value={horse.showName}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label='Owner'
              select
              required
              aria-label='Owner'
              onChange={handleInputChange}
              value={horse.owner}
              name='owner'
              fullWidth
            >
              {Object.keys(owners).map((owner: string, index: number) => {
                return (
                  <MenuItem key={index} value={owner}>
                    {owners[owner]}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop='1em'>
          <Grid item xs={12} sm={4}>
            <TextField
              label='Birth Date'
              required
              type='date'
              onChange={handleInputChange}
              value={horse.birthDate}
              name='birthDate'
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label='Type'
              select
              required
              aria-label='Type'
              onChange={handleInputChange}
              value={horse.type}
              name='type'
              fullWidth
            >
              {Object.keys(horseType).map((type: string, index: number) => {
                return (
                  <MenuItem key={index} value={type}>
                    {horseType[Number(type)]}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label='Arrival Date'
              type='date'
              onChange={handleInputChange}
              value={horse.startDate}
              name='startDate'
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <FormSubmitButton />
      </form>
    </Container>
  );
}

export default AddHorse;
