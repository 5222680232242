import { UnformattedDailyUsage, UsageTypeAndDescription } from '../../types/Usage';
import { ChangeEvent, useState } from 'react';
import { HttpClient } from '../../helpers/httpClient';
import ConfirmDeletionModal from '../Modals/ConfirmDeletionModal';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Person } from '../../types/People';
import { Autocomplete, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import HorseHours from './HorseHours';

function DisplayHourMinUsageRow(props: {
  usage: UnformattedDailyUsage;
  typeChanges?: UsageTypeAndDescription[];
  riders?: Person[];
  setErrorMessage: Function;
  updateUsage: Function;
  getUsage: Function;
}) {
  const { usage, setErrorMessage, getUsage, updateUsage, typeChanges, riders } = props;
  const [mode, setMode] = useState<'view' | 'edit' | 'delete'>(props.usage.id ? 'view' : 'edit');

  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);
  const [duration, setDuration] = useState({
    hours: `${Math.floor(usage.duration / 60)}`,
    mins: `${usage.duration % 60}`,
  });
  const [type, setType] = useState(usage.usageType);

  function handleDurationUsageChange(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;

    const updated = {
      ...duration,
    };

    if (name === 'hours') {
      updated.hours = e.target.value;
    } else {
      updated.mins = e.target.value;
    }

    setDuration(updated);
  }

  async function handleSubmit() {
    setButtonsDisabled(true);
    setErrorMessage('');
    if (isNaN(Number(duration.hours)) || isNaN(Number(duration.mins))) {
      setErrorMessage('Hours and mins must be a number');
      setButtonsDisabled(false);
      return;
    }

    if (Number(duration.hours) < 0 || Number(duration.mins) < 0) {
      setErrorMessage('Hours and mins cannot be negative');
      setButtonsDisabled(false);
      return;
    }

    if (60 * Number(duration.hours) + Number(duration.mins) <= 0) {
      setErrorMessage('Usage duration must be greater than 0');
      setButtonsDisabled(false);
      return;
    }

    if (riders && !usage.rider) {
      setErrorMessage('You must set a rider');
      setButtonsDisabled(false);
      return;
    }

    if (typeChanges && !type) {
      setErrorMessage('You must set a type');
      setButtonsDisabled(false);
      return;
    }

    try {
      const result = await HttpClient.put(`/usage/${usage.id}`, {
        ...usage,
        duration: 60 * Number(duration.hours) + Number(duration.mins),
        usageType: type,
      });
      console.log(result);
      setMode('view');
      await getUsage();
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
    setButtonsDisabled(false);
  }

  async function handleDelete() {
    setButtonsDisabled(true);
    setErrorMessage('');
    try {
      const result = await HttpClient.delete(`/usage/${usage.id}`);
      console.log(result);
      setMode('view');
      await getUsage();
    } catch (error) {
      setErrorMessage((error as Error).message);
    }

    setButtonsDisabled(false);
  }

  let colSize = 6;
  if (typeChanges && riders) {
    colSize = 3;
  } else if (typeChanges || riders) {
    colSize = 4;
  }

  if (mode === 'edit') {
    return (
      <Grid
        container
        columnSpacing={2}
        rowSpacing={2}
        key={usage.id}
        id={`${usage.id}`}
        marginTop={'0.5em'}
      >
        {typeChanges ? (
          <Grid item xs={12} sm={colSize}>
            <TextField
              label='Type'
              select
              required
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setType(e.target.value);
              }}
              value={type}
              name='type'
              fullWidth
            >
              {typeChanges.map((type, index) => {
                return (
                  <MenuItem value={type.code} key={index}>
                    {type.description}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        ) : null}
        <Grid item xs={12} sm={colSize}>
          <HorseHours
            hours={duration.hours}
            mins={duration.mins}
            handleChange={handleDurationUsageChange}
          />
        </Grid>
        {riders ? (
          <Grid item xs={12} sm={colSize}>
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
              getOptionKey={(option) => option.rider}
              value={usage.rider ? usage : null}
              isOptionEqualToValue={(option, value) => option.rider === value.rider}
              options={riders.map((rider) => {
                return {
                  rider: rider.id,
                  firstName: rider.firstName,
                  lastName: rider.lastName,
                };
              })}
              renderInput={(params) => <TextField {...params} label='Rider' />}
              onChange={(event, selectedOption) => {
                const nextUsage = {
                  ...usage,
                  rider: selectedOption ? selectedOption.rider : '',
                  firstName: selectedOption ? selectedOption.firstName : '',
                  lastName: selectedOption ? selectedOption.lastName : '',
                };
                updateUsage(usage.id, nextUsage);
              }}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={colSize} alignContent={'center'}>
          <Button
            variant='contained'
            color='success'
            onClick={handleSubmit}
            disabled={buttonsDisabled}
            fullWidth
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container key={usage.id} marginTop={'1em'}>
      <ConfirmDeletionModal
        show={mode === 'delete'}
        handleClose={() => setMode('view')}
        handleConfirm={handleDelete}
      />
      <Grid container id={`${usage.id}`} marginBottom={'1em'} columnSpacing={2} rowSpacing={1}>
        {typeChanges ? (
          <Grid item xs={12} sm={colSize}>
            <Typography fontWeight={'bold'}>Usage Type</Typography>
            <Typography>{usage.usageType}</Typography>
          </Grid>
        ) : null}
        <Grid item xs={12} sm={colSize}>
          <Typography fontWeight={'bold'}>Duration</Typography>
          <Typography>
            {duration.hours} hours, {duration.mins} mins
          </Typography>
        </Grid>
        {riders ? (
          <Grid item xs={12} sm={colSize}>
            <Typography fontWeight={'bold'}>Rider</Typography>
            <Typography>{usage.firstName + ' ' + usage.lastName}</Typography>
          </Grid>
        ) : null}
        <Grid container item xs={12} sm={colSize} spacing={1} alignContent={'center'}>
          <Grid item xs={12} sm={6}>
            <Button
              variant='contained'
              color='success'
              id={`delete-${usage.id}`}
              onClick={() => setMode('edit')}
              style={{ marginRight: '0.5em' }}
              disabled={buttonsDisabled}
              fullWidth
            >
              <EditIcon style={{ width: '20', paddingBottom: '3.5px' }} />
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant='contained'
              color='error'
              id={`delete-${usage.id}`}
              onClick={() => setMode('delete')}
              disabled={buttonsDisabled}
              fullWidth
            >
              <DeleteIcon style={{ width: '20', paddingBottom: '3.5px' }} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DisplayHourMinUsageRow;
