import { ChangeEvent, useState } from 'react';
import { Horse } from '../types/Horses';
import { MenuItem, TextField } from '@mui/material';

function HorseSelect(props: { horses: Horse[]; onChange: Function }) {
  const [horse, setHorse] = useState<string>('');

  return (
    <TextField
      label='Horse'
      select
      required
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        setHorse(e.target.value);
        props.onChange(e);
      }}
      value={horse}
      name='horse'
      fullWidth
    >
      <MenuItem value=''>
        <em>None</em>
      </MenuItem>
      {props.horses.map((horse) => {
        return (
          <MenuItem key={horse.id} value={horse.id}>
            {horse.barnName}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

export default HorseSelect;
