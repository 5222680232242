import { useEffect, useState } from 'react';
import { Horse } from '../../types/Horses';
import { useNavigate } from 'react-router-dom';
import { HttpClient } from '../../helpers/httpClient';
import ErrorBanner from '../../components/ErrorBanner';
import { Container, Grid, Typography } from '@mui/material';
import SearchWithButton from '../../components/SearchWithButton';
import HorseCards from '../../components/Horses/HorseCards';
import SearchWithButtons from '../../components/SearchWithButtons';

function Horses() {
  const [searchField, setSearchField] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [horses, setHorses] = useState<Horse[]>([]);
  const navigate = useNavigate();

  async function getHorses(setHorses: Function) {
    try {
      const result = await HttpClient.get('/horses');
      setHorses(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  useEffect(() => {
    getHorses(setHorses);
  }, []);

  const filteredHorses = horses.filter((horse) => {
    return (
      horse.barnName.toLowerCase().includes(searchField.toLowerCase()) ||
      (horse.showName ?? '').toLowerCase().includes(searchField.toLowerCase())
    );
  });

  const handleChange = (e: any) => {
    setSearchField(e.target.value);
  };

  return (
    <Container style={{ paddingTop: '1em' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Horses
        </Typography>
      </Grid>
      <SearchWithButtons
        label='Search horses'
        searchValue={searchField}
        setSearchValue={setSearchField}
        dropdownOptions={[
          { location: '/horses/new', display: 'Add Horse' },
          { location: '/horses/farrier/new', display: 'Add Farrier Appointment' },
        ]}
      />
      <ErrorBanner errorHeading='Error getting horses' errorMessage={errorMessage} />
      <HorseCards filteredHorses={filteredHorses} />
    </Container>
  );
}

export default Horses;
