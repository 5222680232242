import { UnformattedDailyUsage, UsageType, UsageTypeAndDescription } from '../../types/Usage';
import { useEffect, useState } from 'react';

import { HttpClient } from '../../helpers/httpClient';
import DisplayHourMinUsageRow from './DisplayHourMinRow';
import { Person } from '../../types/People';
import { Box, Grid, Typography } from '@mui/material';

const dayString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function DisplayHourMinUsage(props: {
  usageType: UsageType;
  title: string;
  selectedDate: string;
  selectedHorse: string;
  setErrorMessage: Function;
  typeChanges?: UsageTypeAndDescription[];
  riders?: Person[];
  unstrictFilter?: boolean;
}) {
  const [usage, setUsage] = useState<UnformattedDailyUsage[]>([]);

  const { setErrorMessage, selectedHorse, selectedDate, usageType, title, typeChanges, riders } =
    props;

  function updateUsage(usageId: number, updatedUsage: UnformattedDailyUsage) {
    const nextUsage = usage.map((usage: UnformattedDailyUsage) => {
      if (usageId === usage.id) {
        return updatedUsage;
      }

      return usage;
    });

    setUsage(nextUsage);
  }

  async function getUsage(
    setUsage: Function,
    setErrorMessage: Function,
    selectedDate: string,
    selectedHorse: string
  ) {
    try {
      const result: UnformattedDailyUsage[] = await HttpClient.get(
        `/usage/day?date=${encodeURIComponent(selectedDate)}&horse=${encodeURIComponent(
          selectedHorse
        )}&formatted=false`
      );
      setUsage(
        props.unstrictFilter
          ? result.filter((usage: UnformattedDailyUsage) => {
              return usage.usageType.includes(usageType);
            })
          : result.filter((usage: UnformattedDailyUsage) => {
              return usage.usageType === usageType;
            })
      );
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  useEffect(() => {
    getUsage(setUsage, setErrorMessage, selectedDate, selectedHorse);
  }, []);

  if (usage.length <= 0) {
    return null;
  }

  let colSize = 6;
  if (typeChanges && riders) {
    colSize = 3;
  } else if (typeChanges || riders) {
    colSize = 4;
  }

  return (
    <Grid marginTop='1em'>
      <Typography variant='h5' fontWeight={'bold'} marginBottom='1em'>
        {title}
      </Typography>
      {usage.map((usage: UnformattedDailyUsage, index: number) => {
        return (
          <Box
            sx={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white' }}
            padding={'0.5em'}
            key={index}
          >
            <Typography variant='h6' fontWeight={'bold'}>
              {title} #{index + 1}
            </Typography>
            <DisplayHourMinUsageRow
              key={index}
              typeChanges={typeChanges}
              riders={riders}
              usage={usage}
              setErrorMessage={setErrorMessage}
              getUsage={() => getUsage(setUsage, setErrorMessage, selectedDate, selectedHorse)}
              updateUsage={updateUsage}
            />
          </Box>
        );
      })}
      <Typography variant='caption' color={'grey'}>
        To add this usage for this horse please go to the Add Usage page
      </Typography>
    </Grid>
  );
}

export default DisplayHourMinUsage;
