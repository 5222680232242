import { useState } from 'react';
import ErrorBanner from '../ErrorBanner';
import { HttpClient } from '../../helpers/httpClient';
import BasicModal from '../Modals/BasicModal';
import EmergencyContact from './EmergencyContacts';
import { Contact } from '../../types/People';

function AddEmergencyContactModal(props: {
  personId: string;
  show: boolean;
  default?: Contact;
  handleClose: () => void;
  handleConfirm: Function;
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [contact, setContact] = useState<Contact>(
    props.default
      ? props.default
      : {
          id: '',
          type: 'EmergencyContact',
          relationship: '',
          firstName: '',
          lastName: '',
          phoneNumber: '',
        }
  );

  async function handleAddContact() {
    setErrorMessage('');

    if (!contact.firstName || !contact.lastName || !contact.relationship || !contact.phoneNumber) {
      setErrorMessage(
        'Emergency contact must have a first name, last name, relationship and phone number'
      );
      return;
    }

    try {
      if (props.default) {
        await HttpClient.put(`/people/${props.personId}/contacts/${contact.id}`, contact);
      } else {
        await HttpClient.post(`/people/${props.personId}/contacts`, { ...contact, id: undefined });
      }

      setContact({
        id: '',
        type: 'EmergencyContact',
        relationship: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
      });
      await props.handleConfirm();
      await props.handleClose();
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  return (
    <BasicModal
      open={props.show}
      onClose={props.handleClose}
      title={`${props.default ? 'Update' : 'Add'} Emergency Contact`}
      submitButton={{
        color: 'success',
        text: props.default ? 'Update' : 'Add',
        onClick: async (event) => {
          await handleAddContact();
        },
      }}
      closeButton
    >
      <ErrorBanner errorHeading='Error adding emergency contact' errorMessage={errorMessage} />
      <EmergencyContact riderContact={contact} setRiderContact={setContact} />
    </BasicModal>
  );
}

export default AddEmergencyContactModal;
