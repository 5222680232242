import React, { Component, ComponentType, MouseEventHandler, ReactNode, useState } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Container, Paper } from '@mui/material';

export type ButtonDetails = {
  color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  text: string;
  onClick: MouseEventHandler;
};

const BasicModal = (props: {
  open: boolean;
  onClose: MouseEventHandler;
  title: string;
  children: ReactNode;
  submitButton: ButtonDetails;
  closeButton?: boolean;
}) => {
  const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center', // Align items vertically
    width: '100%',
  };

  const titleContainerStyle = {
    width: '100%',
    display: 'flex', // Make it a flex container
    justifyContent: 'space-between', // Ensure the title and button are spaced apart
    alignItems: 'center', // Align items vertically
  };

  const closeButtonStyle = {
    marginLeft: 'auto',
  };
  const { open, onClose, title, children, submitButton, closeButton } = props;
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);

  return (
    <Modal open={open} onClose={onClose} style={modalStyle}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxHeight: '80%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Paper sx={{ p: 2, marginBottom: '0px', borderRadius: 0 }}>
          <div style={headerStyle}>
            <div style={titleContainerStyle}>
              <Typography variant='h6'>{title}</Typography>
              <Button
                onClick={onClose}
                style={closeButtonStyle}
                color='primary'
                disabled={buttonsDisabled}
              >
                <CloseIcon />
              </Button>
            </div>
          </div>
        </Paper>
        <Box sx={{ overflowY: 'auto', flex: 1, backgroundColor: 'white' }}>
          {' '}
          <Paper
            sx={{
              p: 2,
              marginTop: '0px',
              borderRadius: 0,
              borderRight: 'none',
              overflowY: 'auto',
              flex: 1,
              backgroundColor: 'white',
            }}
          >
            {children}{' '}
          </Paper>
        </Box>
        <Box textAlign='right'>
          <Paper sx={{ borderRadius: 0 }}>
            {closeButton ? (
              <Button
                onClick={onClose}
                variant='contained'
                color='inherit'
                disabled={buttonsDisabled}
                style={{ margin: '1em' }}
              >
                Close
              </Button>
            ) : null}
            <Button
              onClick={async (event) => {
                setButtonsDisabled(true);
                await submitButton.onClick(event);
                setButtonsDisabled(false);
              }}
              variant='contained'
              color={submitButton.color}
              disabled={buttonsDisabled}
              style={{ margin: '1em' }}
            >
              {submitButton.text}
            </Button>
          </Paper>
        </Box>
      </Box>
    </Modal>
  );
};

export default BasicModal;
