import { Horse } from '../../types/Horses';
import { ChangeEvent } from 'react';
import { Grid, Typography } from '@mui/material';
import HorseHours from './HorseHours';

type KeyValue = {
  [key: string]: { mins: string; hours: string };
};

function HorseHoursTable(props: {
  horses: Horse[];
  setErrorMessage: Function;
  horseHours: KeyValue;
  setHorseHours: Function;
  errors: string[];
}) {
  function handleInputChange(e: ChangeEvent<HTMLInputElement>, horseId: string) {
    const name = e.target.name;
    if (horseId) {
      const updated = {
        ...props.horseHours,
      };

      if (name === 'hours') {
        updated[horseId].hours = e.target.value;
      } else {
        updated[horseId].mins = e.target.value;
      }

      props.setHorseHours(updated);
    }
  }

  return (
    <>
      <Grid container marginBottom='1em' columnSpacing={2} marginLeft='0em' paddingRight='1em'>
        <Grid item xs={4} sx={{ fontWeight: 'bold' }}>
          Horse
        </Grid>
        <Grid item xs={8} sx={{ fontWeight: 'bold' }}>
          Duration
        </Grid>
      </Grid>

      {props.horses.map((horse, index) => (
        <Grid
          container
          key={index}
          id={horse.id}
          columnSpacing={2}
          sx={{
            backgroundColor: props.errors.includes(horse.id!)
              ? 'rgb(248, 215, 218)'
              : index % 2 === 0
              ? '#f5f5f5'
              : 'white',
          }}
          marginLeft='0em'
          paddingRight='1em'
          paddingTop='1em'
          paddingBottom='1em'
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid item xs={4}>
            {horse.barnName}
          </Grid>
          <Grid item xs={8}>
            <HorseHours
              hours={props.horseHours[horse.id!].hours}
              mins={props.horseHours[horse.id!].mins}
              handleChange={(event) => {
                handleInputChange(event, horse.id!);
              }}
              removeEnd
            />
          </Grid>
        </Grid>
      ))}
    </>
  );
}

export default HorseHoursTable;
