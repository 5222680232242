import { ChangeEvent, ChangeEventHandler, MouseEventHandler, useState } from 'react';
import { FeedTime, FeedType, HorseFeed } from '../../types/Horses';
import ErrorBanner from '../ErrorBanner';
import { HttpClient } from '../../helpers/httpClient';
import Hay from './Hay';
import OtherFeed from './OtherFeed';
import BasicModal from './BasicModal';
import { Button } from '@mui/material';

function AddFeedModal(props: {
  type: FeedType.Hay | 'other' | 'closed';
  horse: string;
  feed: HorseFeed | undefined;
  show: boolean;
  handleClose: () => void;
  handleConfirm: Function;
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [feed, setFeed] = useState<HorseFeed>(
    props.feed
      ? props.feed
      : {
          feedType: props.type === FeedType.Hay ? FeedType.Hay : FeedType.None,
          feedTime: FeedTime.None,
          horse: props.horse,
          amount: props.type === FeedType.Hay ? '2-1-1-2' : '',
          details: '',
          subType: '',
        }
  );

  async function handleSubmit() {
    setErrorMessage('');
    const form: any = document.getElementById('feedForm');

    try {
      if (form && form.checkValidity()) {
        if (props.feed) {
          const result = await HttpClient.put(`/horses/${props.horse}/feed/${props.feed.id}`, {
            ...feed,
            feedTime: feed.feedTime !== '' ? feed.feedTime : undefined,
          });
          console.log(result);
        } else {
          const result = await HttpClient.post(`/horses/${props.horse}/feed`, {
            ...feed,
            feedTime: feed.feedTime !== '' ? feed.feedTime : undefined,
          });
          console.log(result);
        }

        props.handleConfirm();
        props.handleClose();
      }
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    let newValue: string | boolean | number = value;

    // reset the object to the default if we've switched the feedtype
    if (name === 'feedType') {
      const selectedType = newValue as FeedType;
      setFeed((prevState) => ({
        ...prevState,
        feedType: selectedType,
        feedTime: FeedTime.None,
        amount: selectedType === FeedType.Hay ? '2-1-1-2' : '',
        details: '',
        subType: '',
      }));
      return;
    }

    setFeed((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  function handleHayChange(value: { amount: string; valid: boolean }) {
    if (value.valid) {
      setFeed({
        ...feed,
        amount: value.amount,
      });
    } else {
      setFeed({
        ...feed,
        amount: '',
      });
    }
  }

  return (
    <BasicModal
      open={props.show}
      onClose={props.handleClose}
      title='Add Feed'
      submitButton={{
        color: 'success',
        text: props.feed ? 'Update' : 'Add',
        onClick: async (event) => {
          await handleSubmit();
        },
      }}
      closeButton
    >
      <ErrorBanner errorHeading='Error adding feed' errorMessage={errorMessage} />
      <form id='feedForm'>
        {props.type === FeedType.Hay ? (
          <Hay
            hay={feed}
            default={{
              first: '2',
              second: '1',
              third: '1',
              fourth: '2',
            }}
            handleInputChange={handleHayChange}
          />
        ) : (
          <OtherFeed
            feed={feed}
            handleInputChange={handleInputChange}
            update={props.feed !== undefined}
          />
        )}
      </form>
    </BasicModal>
  );
}

export default AddFeedModal;
