import { ChangeEvent, useState } from 'react';
import { VolunteerHourAndName } from '../../types/People';
import { HttpClient } from '../../helpers/httpClient';
import { Grid, TextField } from '@mui/material';
import BasicModal from '../../components/Modals/BasicModal';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ErrorBanner from '../../components/ErrorBanner';
import dayjs from 'dayjs';
import HorseHours from '../../components/Usage/HorseHours';
import { Lesson } from '../../types/Lessons';
import LessonData from './LessonData';

function EditLesson(props: {
  lesson?: Lesson;
  onSubmit: Function;
  onClose: () => void;
  show: boolean;
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [lesson, setLesson] = useState<Lesson>(
    props.lesson
      ? props.lesson
      : {
          program: '',
          lessonType: '',
          startDate: '',
          endDate: '',
        }
  );
  const { show, onSubmit, onClose } = props;

  if (!lesson) {
    return null;
  }

  const handleSubmit = async () => {
    try {
      const result = await HttpClient.put(`/lessons/${lesson.id}`, lesson);
      onSubmit();
      onClose();
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  };

  return (
    <BasicModal
      onClose={onClose}
      open={show}
      title='Update Lesson'
      submitButton={{
        color: 'success',
        text: 'Update',
        onClick: async (event) => {
          await handleSubmit();
        },
      }}
      closeButton
    >
      <ErrorBanner errorHeading='Error editing lesson' errorMessage={errorMessage} />
      <form id='volunteerForm'>
        <LessonData lesson={lesson} setLesson={setLesson} />
      </form>
    </BasicModal>
  );
}

export default EditLesson;
