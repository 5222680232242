import { ReactComponent as HorseHeadImg } from './horse-head-solid.svg';

export default function HorseHead() {
  return (
    <HorseHeadImg
      height={20}
      width={20}
      style={{ paddingBottom: '3.5px', paddingRight: '3.5px' }}
    />
  );
}

export function AdjustableHorsHead() {
  return <HorseHeadImg height={40} width={40} />;
}
