import { Contact, RiderLesson } from '../../types/People';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Grid, Typography } from '@mui/material';

function DisplayRiderContacts(props: {
  title: string;
  riderContacts: Contact[] | undefined;
  buttonsDisabled: boolean;
  onDeleteClick: Function;
  onEditClick: Function;
}) {
  if (!props.riderContacts || props.riderContacts.length <= 0) {
    return (
      <>
        <Typography variant='h4' marginTop={'0.5em'} marginBottom={'0.5em'}>
          {props.title}
        </Typography>
        No contacts
      </>
    );
  }

  return (
    <>
      <Grid container marginTop='1em'>
        <Typography variant='h4' marginBottom={'1em'}>
          {props.title}
        </Typography>
      </Grid>
      {props.riderContacts.map((contact, index) => {
        return (
          <Box
            sx={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white' }}
            padding={'1em'}
            key={contact.id}
          >
            <Typography variant='h6' fontWeight={'bold'}>
              Emergency Contact #{index + 1}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography fontWeight={'bold'}>First Name</Typography>
                <Typography>{contact.firstName}</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography fontWeight={'bold'}>Last Name</Typography>
                <Typography>{contact.lastName}</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography fontWeight={'bold'}>Relationship</Typography>
                <Typography>{contact.relationship}</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography fontWeight={'bold'}>Phone Number</Typography>
                <Typography>{contact.phoneNumber}</Typography>
              </Grid>
              <Grid item xs={12} sm={2} alignContent={'center'}>
                <Button
                  color='error'
                  variant='contained'
                  onClick={() => {
                    props.onDeleteClick(contact.id);
                  }}
                  disabled={props.buttonsDisabled}
                  fullWidth
                >
                  <DeleteIcon style={{ width: '20', paddingBottom: '3.5px' }} />
                </Button>
              </Grid>
              <Grid item xs={12} sm={2} alignContent={'center'}>
                <Button
                  color='success'
                  variant='contained'
                  onClick={() => {
                    props.onEditClick(contact);
                  }}
                  disabled={props.buttonsDisabled}
                  fullWidth
                >
                  <EditIcon style={{ width: '20', paddingBottom: '3.5px' }} />
                </Button>
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </>
  );
}

export default DisplayRiderContacts;
