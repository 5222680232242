import { useEffect, useState } from 'react';
import { Horse, HorseSaddle } from '../../types/Horses';
import { useNavigate } from 'react-router-dom';
import { HttpClient } from '../../helpers/httpClient';
import ErrorBanner from '../../components/ErrorBanner';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Container, Grid, Typography } from '@mui/material';
import HorseViewSaddleComponent from '../../components/Horses/HorseViewSaddleComponent';

function Saddles() {
  const [errorMessage, setErrorMessage] = useState('');
  const [horses, setHorses] = useState<Horse[]>([]);
  const [horseSaddles, setHorseSaddles] = useState<{ [key: string]: HorseSaddle[] }>({});
  const navigate = useNavigate();

  async function getHorses() {
    try {
      const result: Horse[] = await HttpClient.get('/horses');
      setHorses(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  async function getHorseSaddles() {
    try {
      const result: { [key: string]: HorseSaddle[] } = await HttpClient.get('/horses/saddles');
      setHorseSaddles(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  useEffect(() => {
    getHorses();
    getHorseSaddles();
  }, []);

  return (
    <Container style={{ paddingTop: '1em' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Saddles
        </Typography>
      </Grid>
      <ErrorBanner errorHeading='Error getting saddles' errorMessage={errorMessage} />
      <>
        {horses.map((horse, index) => (
          <Grid
            container
            key={index}
            id={horse.id}
            columnSpacing={2}
            sx={{
              backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white',
            }}
            marginLeft='-0.5em'
            paddingRight='1em'
            paddingTop='1em'
            paddingBottom='1em'
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Grid item xs={12} sm={2}>
              <Typography
                variant='h6'
                sx={{
                  fontWeight: 'bold',
                  marginTop: '0.5em',
                }}
              >
                {horse.barnName}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              marginBottom={'0.5em'}
              sx={{ marginLeft: { xs: '1em', sm: '0em' } }}
            >
              {horseSaddles[horse.id!]?.map((horseSaddle, index) => {
                return (
                  <HorseViewSaddleComponent
                    saddle={horseSaddle}
                    index={index}
                    key={horseSaddle.id}
                  />
                );
              })}
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant='contained'
                color='success'
                onClick={() => {
                  navigate(`/horses/saddles/${horse.id}`);
                }}
                style={{ marginRight: '0.5em' }}
                fullWidth
              >
                <EditIcon style={{ width: '20', paddingBottom: '3.5px' }} />
              </Button>
            </Grid>
          </Grid>
        ))}
      </>
    </Container>
  );
}

export default Saddles;
