import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { formatUsageTime } from '../helpers/utils';
import { Horse } from '../types/Horses';
import { DayToUsage, UsageDurationType, WeeklyUsage } from '../types/Usage';

const dayString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function formatUsage(u?: UsageDurationType[]) {
  let usageString: string[] = [];

  if (!u) {
    return '';
  }

  u.forEach((usageDuration) => {
    usageString.push(
      usageDuration.usageType === 'FL'
        ? usageDuration.usageType
        : usageDuration.usageType.includes('UM')
        ? `UM: ${formatUsageTime(usageDuration.duration)}`
        : `${usageDuration.usageType}: ${formatUsageTime(usageDuration.duration)}`
    );
  });

  return usageString.join('\n');
}

function getUsageSumByType(horse: DayToUsage) {
  let usageTypeSum: { [key: string]: number } = {};
  Object.keys(horse).forEach((day) => {
    horse[day].forEach((usage) => {
      let usageType = usage.usageType;
      if (usageType.includes('UM')) {
        usageType = 'UM';
      }

      if (!(usageType in usageTypeSum)) {
        usageTypeSum[usageType] = 0;
      }

      usageTypeSum[usageType] += usage.duration;
    });
  });

  let usageString: string[] = [];
  Object.keys(usageTypeSum).forEach((key) => {
    if (key === 'FL') {
      usageString.push(`${key}: ${usageTypeSum[key] / 10}`);
      return;
    }

    usageString.push(`${key}: ${formatUsageTime(usageTypeSum[key])}`);
  });

  return usageString.join('\n ');
}

function getUsageSum(horse: DayToUsage) {
  let sum = 0;
  Object.keys(horse).forEach((day) => {
    horse[day].forEach((usage) => {
      if (usage.usageType === 'FL' || usage.usageType == 'RA') {
        return;
      }

      sum += usage.duration;
    });
  });

  return formatUsageTime(sum);
}

function WeeklyUsages(props: { usage: WeeklyUsage; horses: Horse[] }) {
  if (Object.keys(props.usage).length === 0) {
    return null;
  }

  const days = Object.keys(props.usage[props.horses[0].id!]);

  return (
    <TableContainer component={Paper} sx={{ maxHeight: '70vh' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Horse</TableCell>
            {days.map((day) => (
              <TableCell key={`horse-day-${day}`} sx={{ fontWeight: 'bold' }}>
                {dayString[new Date(day).getUTCDay()]}
              </TableCell>
            ))}
            <TableCell sx={{ fontWeight: 'bold' }}>Subtotal</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.horses.map((horse) => (
            <TableRow key={horse.id!}>
              <TableCell>{horse.barnName}</TableCell>
              {days.map((day) => (
                <TableCell key={horse.id! + day}>
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {formatUsage(props.usage[horse.id!][day])}
                  </span>
                </TableCell>
              ))}
              <TableCell key={horse.id! + 'type-total'}>
                <span style={{ whiteSpace: 'pre-line' }}>
                  {getUsageSumByType(props.usage[horse.id!])}
                </span>
              </TableCell>
              <TableCell key={horse.id! + 'total'}>
                <span style={{ whiteSpace: 'pre-line' }}>
                  {getUsageSum(props.usage[horse.id!])}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default WeeklyUsages;
