import { ChangeEvent, ChangeEventHandler, MouseEventHandler, useState } from 'react';
import { FeedTime, FeedType, HorseFeed } from '../../types/Horses';
import ErrorBanner from '../ErrorBanner';
import { HttpClient } from '../../helpers/httpClient';
import { Box, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import BasicModal from '../Modals/BasicModal';
import { formatTime } from '../../helpers/utils';
import { Lesson } from '../../types/Lessons';
import dayjs from 'dayjs';
import { RiderAndLesson } from '../../types/People';
import { useNavigate } from 'react-router-dom';

function DuplicateCreationModal(props: {
  duplicates: RiderAndLesson[];
  show: boolean;
  handleClose: () => void;
  handleConfirm: Function;
}) {
  const navigate = useNavigate();

  return (
    <BasicModal
      open={props.show}
      onClose={props.handleClose}
      title='Possible Duplicate Rider'
      submitButton={{
        color: 'success',
        text: 'Proceed with creation',
        onClick: async (event) => {
          await props.handleConfirm();
        },
      }}
      closeButton
    >
      <Box>
        <Typography marginBottom={'0.5em'}>
          We've found people with a similar name in the system. Do you still want to create a new
          person?
        </Typography>
        <Grid container spacing={2} marginBottom={'0.5em'}>
          <Grid item xs={12} sm={4} fontWeight={'bold'}>
            Name
          </Grid>
          <Grid item xs={12} sm={4} fontWeight={'bold'}>
            Lessons
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
        </Grid>
        {props.duplicates.map((duplicate: RiderAndLesson) => {
          return (
            <Grid container spacing={2} key={duplicate.id} marginBottom={'0.5em'}>
              <Grid item xs={12} sm={4}>
                {duplicate.firstName} {duplicate.lastName}
              </Grid>
              <Grid item xs={12} sm={4}>
                {duplicate.lessonInfo
                  ? duplicate.lessonInfo.currentLessons
                      .map((lesson) => {
                        return `${lesson.lessonDay ?? ''} ${
                          lesson.lessonTime ? formatTime(lesson.lessonTime) : ''
                        }`;
                      })
                      .join(', ')
                  : ''}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant='contained'
                  color='success'
                  onClick={() =>
                    navigate(
                      {
                        pathname: `/people/${duplicate.id}`,
                      },
                      {
                        state: {
                          person: duplicate,
                        },
                      }
                    )
                  }
                >
                  Go to person
                </Button>
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </BasicModal>
  );
}

export default DuplicateCreationModal;
