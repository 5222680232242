import { formatTime } from '../../helpers/utils';
import { Lesson } from '../../types/Lessons';
import { UnformattedDailyUsage } from '../../types/Usage';
import { ChangeEvent, useState } from 'react';
import { HttpClient } from '../../helpers/httpClient';
import ConfirmDeletionModal from '../Modals/ConfirmDeletionModal';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Person } from '../../types/People';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

function DisplayHorsemanshipUsageRow(props: {
  usage: UnformattedDailyUsage;
  riders: Person[];
  lessons: Lesson[];
  setErrorMessage: Function;
  updateUsage: Function;
  getUsage: Function;
}) {
  const [mode, setMode] = useState<'view' | 'edit' | 'delete'>(props.usage.id ? 'view' : 'edit');
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);

  const { usage, riders, lessons, setErrorMessage, getUsage, updateUsage } = props;

  function handleUsageChange(e: ChangeEvent<HTMLInputElement>) {
    let value: string | number = e.target.value;
    let targetName = e.target.name;

    if (targetName === 'absent') {
      targetName = 'usageType';
      value = e.target.checked ? 'RA' : 'G';
    }

    if (targetName == 'lesson') {
      value = Number(value);
    }

    const nextUsage = {
      ...usage,
      [targetName]: value,
    };
    updateUsage(usage.id, nextUsage);
  }

  async function handleSubmit() {
    setButtonsDisabled(true);
    setErrorMessage('');

    if (!usage.lesson || Number(usage.lesson) < 0) {
      setErrorMessage('Must select a lesson');
      setButtonsDisabled(false);
      return;
    }

    if (!usage.rider) {
      setErrorMessage('Must select a rider');
      setButtonsDisabled(false);
      return;
    }

    // No id means this is new usage so create it
    if (!usage.id) {
      try {
        const result = await HttpClient.post('/usage', [usage]);
        console.log('result', result);
        setMode('view');
        await getUsage();
      } catch (error) {
        setErrorMessage((error as Error).message);
      }

      setButtonsDisabled(false);
      return;
    }

    try {
      const result = await HttpClient.put(`/usage/${usage.id}`, usage);
      console.log(result);
      setMode('view');
      await getUsage();
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
    setButtonsDisabled(false);
  }

  async function handleDelete() {
    setButtonsDisabled(true);
    try {
      const result = await HttpClient.delete(`/usage/${usage.id}`);
      console.log(result);
      setMode('view');
      await getUsage();
    } catch (error) {
      setErrorMessage((error as Error).message);
    }

    setButtonsDisabled(false);
  }

  if (mode === 'edit') {
    return (
      <Grid
        container
        key={usage.id}
        id={`${usage.id}`}
        marginTop={'0.5em'}
        columnSpacing={2}
        rowSpacing={1}
      >
        <Grid item xs={12} sm={3}>
          <TextField
            label='Lesson'
            select
            required
            onChange={handleUsageChange}
            value={usage.lesson}
            name='lesson'
            fullWidth
          >
            {lessons.map((lesson: any) => {
              return (
                <MenuItem key={lesson.id} value={lesson.id}>
                  {formatTime(lesson.lessonTime)}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            disablePortal
            getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
            getOptionKey={(option) => option.rider}
            value={usage.rider ? usage : null}
            isOptionEqualToValue={(option, value) => option.rider === value.rider}
            options={props.riders.map((rider) => {
              return {
                rider: rider.id,
                firstName: rider.firstName,
                lastName: rider.lastName,
              };
            })}
            renderInput={(params) => <TextField {...params} label='Rider' />}
            onChange={(event, selectedOption) => {
              const nextUsage = {
                ...usage,
                rider: selectedOption ? selectedOption.rider : '',
                firstName: selectedOption ? selectedOption.firstName : '',
                lastName: selectedOption ? selectedOption.lastName : '',
              };
              updateUsage(usage.id, nextUsage);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3} alignContent={'center'}>
          <FormControlLabel
            control={
              <Checkbox
                checked={usage.usageType === 'RA'}
                name='absent'
                onChange={handleUsageChange}
              />
            }
            label='Absent:'
            labelPlacement='start' // This places the label to the left of the checkbox
          />
        </Grid>
        <Grid item xs={12} sm={3} alignContent={'center'}>
          <Button
            variant='contained'
            color='success'
            onClick={handleSubmit}
            disabled={buttonsDisabled}
            fullWidth
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <ConfirmDeletionModal
        show={mode === 'delete'}
        handleClose={() => setMode('view')}
        handleConfirm={handleDelete}
      />
      <Grid
        container
        key={usage.id}
        id={`${usage.id}`}
        marginBottom={'0.5em'}
        columnSpacing={2}
        rowSpacing={1}
        justifyContent={'center'}
      >
        <Grid item xs={12} sm={3}>
          <Typography fontWeight={'bold'}>Lesson Time</Typography>
          <Typography>
            {formatTime(lessons.find((lesson) => lesson.id === usage.lesson)?.lessonTime)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography fontWeight={'bold'}>Rider Name</Typography>
          <Typography>{props.usage.firstName + ' ' + props.usage.lastName}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography fontWeight={'bold'}>Absent</Typography>
          <Typography>{usage.usageType === 'RA' ? 'Yes' : 'No'}</Typography>
        </Grid>
        <Grid container item xs={12} sm={3} spacing={1}>
          <Grid item xs={12} sm={6}>
            <Button
              variant='contained'
              color='success'
              id={`delete-${usage.id}`}
              onClick={() => setMode('edit')}
              style={{ marginRight: '0.5em' }}
              disabled={buttonsDisabled}
              fullWidth
            >
              <EditIcon style={{ width: '20', paddingBottom: '3.5px' }} />
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant='contained'
              color='error'
              id={`delete-${usage.id}`}
              onClick={() => setMode('delete')}
              disabled={buttonsDisabled}
              fullWidth
            >
              <DeleteIcon style={{ width: '20', paddingBottom: '3.5px' }} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default DisplayHorsemanshipUsageRow;
