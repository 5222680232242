import { useEffect, useState } from 'react';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { useNavigate } from 'react-router-dom';
import { HttpClient } from '../../helpers/httpClient';
import ErrorBanner from '../../components/ErrorBanner';
import { FreeLungeReport, RiderAbsenceReport } from '../../types/Metrics';
import {
  Container,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

function Reports() {
  const [days, setDays] = useState('7');
  const [freeLungeReport, setFreeLungeReport] = useState<FreeLungeReport>([]);
  const [riderAbsenceReport, setRiderAbsenceReport] = useState<RiderAbsenceReport>([]);
  const [reportType, setReportType] = useState<string>('freeLunge');
  const [errorMessage, setErrorMessage] = useState('');

  async function getReports(setReports: Function, duration: string, reportType: string) {
    setErrorMessage('');
    if (!duration) {
      return;
    }

    try {
      const result = await HttpClient.get(`/reports/${reportType}?numDays=${duration}`);
      console.log(result);
      setReports(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  useEffect(() => {
    getReports(
      reportType === 'freeLunge' ? setFreeLungeReport : setRiderAbsenceReport,
      days,
      reportType
    );
  }, [days, reportType]);

  const freeLungeCols: GridColDef[] = [
    { field: 'horse', headerName: 'Horse', flex: 1, minWidth: 100 },
    {
      field: 'totalFreeLunges',
      headerName: 'Num Free Lunges',
      flex: 1,
      minWidth: 100,
      type: 'number',
    },
  ];

  const absenceCols: GridColDef[] = [
    { field: 'firstName', headerName: 'First Name', flex: 1, minWidth: 100 },
    { field: 'lastName', headerName: 'Last Name', flex: 1, minWidth: 100 },
    { field: 'totalAbsences', headerName: 'End Date', flex: 1, minWidth: 100 },
  ];

  return (
    <Container style={{ paddingTop: '1em' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Reports
        </Typography>
      </Grid>
      <TextField
        fullWidth
        label='Days'
        variant='outlined'
        type='tel'
        value={days}
        onChange={(e) => setDays(e.target.value)}
        style={{ marginBottom: '1em' }}
      />
      <ToggleButtonGroup
        value={reportType}
        exclusive
        onChange={(
          event: React.MouseEvent<HTMLElement>,
          newFilter: 'freeLunge' | 'riderAbsences' | null
        ) => {
          setReportType(newFilter ?? 'freeLunge');
        }}
        aria-label='report type'
        fullWidth
        color='success'
      >
        <ToggleButton value='freeLunge'>Free Lunge</ToggleButton>
        <ToggleButton value='riderAbsences'>Rider Absences</ToggleButton>
      </ToggleButtonGroup>
      <ErrorBanner errorHeading='Error getting riders' errorMessage={errorMessage} />
      <div style={{ height: '70vh', width: '100%', marginTop: '1em' }}>
        {reportType === 'freeLunge' ? (
          <DataGrid
            getRowId={(row) => row.horse}
            rows={freeLungeReport}
            columns={freeLungeCols}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        ) : (
          <DataGrid
            getRowId={(row) => row.firstName + row.lastName + row.totalAbsences}
            rows={riderAbsenceReport}
            columns={absenceCols}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        )}
      </div>
    </Container>
    // <Container>
    //   <div className='p-3 text-center'>
    //     <h1 className='mb-3'>Reports</h1>
    //   </div>
    //   <ErrorBanner errorHeading='Error getting report' errorMessage={errorMessage} />
    //   <Row>
    //     <InputGroup className='mb-3'>
    //       <InputGroup.Text id='days-label'>Days</InputGroup.Text>
    //       <Form.Control
    //         type='tel'
    //         value={days}
    //         aria-label='Days'
    //         aria-describedby='days-label'
    //         onChange={(e) => setDays(e.target.value)}
    //       />
    //     </InputGroup>
    //     <ButtonGroup>
    //       <ToggleButton
    //         key={0}
    //         id={`free-lunge-report-toggle`}
    //         type='radio'
    //         variant={'outline-info'}
    //         name='radio'
    //         value='freeLunge'
    //         checked={reportType === 'freeLunge'}
    //         onChange={(e) => {
    //           setReportType(e.currentTarget.value);
    //         }}
    //       >
    //         Free Lunge
    //       </ToggleButton>
    //       <ToggleButton
    //         key={1}
    //         id={`rider-absence-report-toggle`}
    //         type='radio'
    //         variant={'outline-info'}
    //         name='radio'
    //         value='riderAbsences'
    //         checked={reportType === 'riderAbsences'}
    //         onChange={(e) => {
    //           setReportType(e.currentTarget.value);
    //         }}
    //       >
    //         Rider Absences
    //       </ToggleButton>
    //     </ButtonGroup>
    //   </Row>
    //   {reportType === 'freeLunge' ? (
    //     <Table striped>
    //       <thead>
    //         <tr>
    //           <th>Horse</th>
    //           <th>Num Free Lunges</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {freeLungeReport.map((report, index) => {
    //           return (
    //             <tr key={`report-${index}`}>
    //               <td>{report.horse}</td>
    //               <td>{report.totalFreeLunges}</td>
    //             </tr>
    //           );
    //         })}
    //       </tbody>
    //     </Table>
    //   ) : (
    //     <Table striped>
    //       <thead>
    //         <tr>
    //           <th>Rider</th>
    //           <th>Num Absences</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {riderAbsenceReport.map((report, index) => {
    //           return (
    //             <tr key={`report-${index}`}>
    //               <td>{report.firstName + ' ' + report.lastName}</td>
    //               <td>{report.totalAbsences}</td>
    //             </tr>
    //           );
    //         })}
    //       </tbody>
    //     </Table>
    //   )}
    // </Container>
  );
}

export default Reports;
