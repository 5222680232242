import { ChangeEvent, useState } from 'react';
import { HorseFarrier } from '../../types/Horses';
import ErrorBanner from '../ErrorBanner';
import { HttpClient } from '../../helpers/httpClient';
import LimitedTextArea from '../LimitedTextArea';
import BasicModal from './BasicModal';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';

function AddFarrierModal(props: {
  horse: string;
  horseName: string;
  farrier: HorseFarrier | undefined;
  show: boolean;
  handleClose: () => void;
  handleConfirm: Function;
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [farrier, setFarrier] = useState<HorseFarrier>(
    props.farrier
      ? props.farrier
      : {
          horse: props.horse,
          type: '',
          appointmentDate: dayjs().format('YYYY-MM-DD'),
        }
  );

  async function handleSubmit() {
    setErrorMessage('');

    const form: any = document.getElementById('farrierForm');

    try {
      if (form && form.checkValidity()) {
        if (farrier.notes && farrier.notes.length > 250) {
          setErrorMessage('Comments section is too long. Please check and reduce the size.');
          return;
        }

        if (farrier.type === '') {
          setErrorMessage('Please select what the farrier did.');
          return;
        }

        if (props.farrier) {
          await HttpClient.put(`/horses/${props.horse}/farrier/${farrier.id}`, farrier);
        } else {
          await HttpClient.post(`/horses/farrier`, [farrier]);
        }

        props.handleConfirm();
        props.handleClose();
      }
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    let newValue: string | boolean | number = e.target.type === 'checkbox' ? checked : value;

    setFarrier((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  return (
    <BasicModal
      onClose={props.handleClose}
      open={props.show}
      title='Add Farrier'
      submitButton={{
        color: 'success',
        text: props.farrier ? 'Update' : 'Add',
        onClick: async (event) => {
          await handleSubmit();
        },
      }}
      closeButton
    >
      <ErrorBanner errorHeading='Error adding farrier appointment' errorMessage={errorMessage} />
      <form id='farrierForm'>
        <Box marginBottom={'1em'}>
          <TextField
            label='Farrier Date'
            type='date'
            value={farrier.appointmentDate}
            name='appointmentDate'
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Box>
        <Box marginBottom={'1em'} justifyContent={'center'}>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>What did they get done?</FormLabel>
            <RadioGroup row name='type' value={farrier.type} onChange={handleInputChange}>
              <FormControlLabel value='Trim' control={<Radio />} label='Trim' />
              <FormControlLabel value='Front Shoes' control={<Radio />} label='Front Shoes' />
              <FormControlLabel value='Back Shoes' control={<Radio />} label='Back Shoes' />
              <FormControlLabel value='4 Shoes' control={<Radio />} label='4 Shoes' />
              <FormControlLabel value='Other' control={<Radio />} label='Other' />
              <FormControlLabel value='' control={<Radio />} label={<em>Not Done</em>} />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box marginBottom={'1em'}>
          <LimitedTextArea
            maxChars={250}
            label='Comments'
            data={farrier.notes ? farrier.notes : ''}
            setData={(e: ChangeEvent<HTMLInputElement>) => {
              setFarrier((prevState) => ({
                ...prevState,
                notes: e.target.value,
              }));
            }}
          />
        </Box>
      </form>
    </BasicModal>
  );
}

export default AddFarrierModal;
