import { Amplify } from 'aws-amplify';
import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios';
import { v4 as uuidv4 } from 'uuid';

export class HttpClient {
  public static transactionIdHeader = uuidv4();
  public static updateHeader(esbTransactionGuid = uuidv4()) {
    this.transactionIdHeader = esbTransactionGuid;
    return this.transactionIdHeader;
  }

  public static async get<T>(urlPath: string, data?: object, queryParams?: object): Promise<T> {
    return this.makeRequest('get', urlPath, data, queryParams);
  }

  public static async post<T>(urlPath: string, data: object): Promise<T> {
    return this.makeRequest('post', urlPath, data);
  }

  public static async put<T>(urlPath: string, data: object): Promise<T> {
    return this.makeRequest('put', urlPath, data);
  }

  public static async delete<T>(urlPath: string, data?: object): Promise<T> {
    return this.makeRequest('delete', urlPath, data);
  }

  private static async makeRequest<T>(method: Method, urlPath: string, data?: object, queryParams?: object): Promise<T> {
    const config = await HttpClient.getConfig(method, urlPath, data, queryParams);
    const response = await axios.request(config).catch((error: AxiosError) => {
      HttpClient.handleAxiosError(error, this.getFullUrl(urlPath));
    });
    return response?.data;
  }

  private static async getConfig(
    method: Method,
    urlPath: string,
    data?: object,
    queryParams?: object
  ): Promise<AxiosRequestConfig> {
    const idempotencyKey = uuidv4();
    const session = await Amplify.Auth.currentSession();

    return {
      baseURL: undefined,
      url: HttpClient.getFullUrl(urlPath),
      method: method,
      headers: {
        'Content-Type': 'application/json',
        transactionId: HttpClient.transactionIdHeader,
        idempotencyKey,
        Authorization: session.getIdToken().getJwtToken(),
      },
      data,
      params: queryParams
    };
  }

  private static getFullUrl(urlPath: string) {
    // This is a fragile approach, easy to break if stuff like "/" is missing
    let fullUrl = process.env.REACT_APP_APIGATEWAYURL + urlPath;
    try {
      let baseURL = process.env.REACT_APP_APIGATEWAYURL;
      if (!baseURL?.includes('http')) baseURL = 'https://' + baseURL;
      fullUrl = this.handleUrlSubdirectories(baseURL, urlPath);
    } catch (error) {
      console.error(`Could not build safe url, defaulting to ${fullUrl}`, {
        baseURL: process.env.REACT_APP_APIGATEWAYURL,
        urlPath,
        protocol: 'https',
      });
    }
    return fullUrl;
  }

  private static handleUrlSubdirectories(baseURL: string, urlPath: string) {
    // Handle the situation when there is a subdirectory in the base url. new Url will drop any extensions in base url
    // move the subdirectories from the base url to the url path
    let noProtocolBaseUrl = baseURL.split('://')[1];
    let modifiedPath = urlPath[0] === '/' ? urlPath.substring(1) : urlPath; // remove '/' from the front of the url path
    if (noProtocolBaseUrl.includes('/') && noProtocolBaseUrl.split('/')[1].length > 0) {
      let splitArray = noProtocolBaseUrl.split('/').filter((x) => x); //filter out empty elements
      baseURL = 'https://' + splitArray.shift(); // move first path to base url
      modifiedPath = splitArray.join('/') + '/' + modifiedPath; // move the rest to the url path
    }
    return new URL(modifiedPath, baseURL).toString();
  }

  private static handleAxiosError(error: AxiosError, url: string) {
    console.warn('Failed Request to %s: %s', url, error.message, error);
    // Goal here is to get some separation from the axios error

    let responseData = error.response?.data as any;
    if (responseData) {
      if (responseData?.error) throw new Error(responseData?.error);
      if (responseData?.message) throw new Error(responseData?.message);
      if (typeof responseData === "string") throw new Error(responseData)
    }
    throw new Error(error.message);
  }
}
