import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HttpClient } from '../../helpers/httpClient';
import ErrorBanner from '../../components/ErrorBanner';
import { Person, RiderAbsence, SubmitVolunteerHour, VolunteerHour } from '../../types/People';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import AbsentRiderRow from '../../components/Riders/AbsentRiderRow';
import { Lesson } from '../../types/Lessons';
import { v4 as uuidv4 } from 'uuid';
import { SubmitButton } from '../../components/SubmitButton';
import { Autocomplete, Box, Button, Container, Grid, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import HorseHours from '../../components/Usage/HorseHours';

function AddVolunteerHours() {
  const location = useLocation();
  const navigate = useNavigate();
  const [date, setDate] = useState<dayjs.Dayjs>(
    location?.state?.date ? location?.state?.date : dayjs()
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState<string[]>([]);
  const [allVolunteers, setAllVolunteers] = useState<Person[]>([]);
  const [volunteers, setVolunteers] = useState<VolunteerHour[]>([
    {
      date: '',
      id: uuidv4(),
      volunteer: '',
      duration: 0,
      firstName: '',
      lastName: '',
      hours: '',
      mins: '',
    },
  ]);

  async function handleSubmit() {
    setLoading(true);

    const data: SubmitVolunteerHour[] = [];
    const errors: string[] = [];
    volunteers.forEach((hour: VolunteerHour) => {
      if (!hour) {
        return;
      }

      if (!hour.volunteer) {
        errors.push(hour.id!);
      }

      if (isNaN(Number(hour.hours)) || isNaN(Number(hour.mins))) {
        errors.push(hour.id!);
      }

      if (!(Number(hour.hours) === 0 && Number(hour.mins) === 0)) {
        data.push({
          volunteer: hour.volunteer,
          duration: 60 * Number(hour.hours) + Number(hour.mins),
          date: date.format('YYYY-MM-DD'),
        });
      } else {
        errors.push(hour.id!);
      }
    });

    if (errors.length > 0) {
      setErrorMessage('Missing volunteer or hours and mins.');
      setErrors(errors);
      setLoading(false);
      return;
    }

    setErrorMessage('');
    try {
      const result = await HttpClient.post('/volunteers/hours', data);
      console.log(result);
      navigate(`/volunteers/hours`);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }

    setLoading(false);
  }

  function addVolunteer() {
    setVolunteers([
      ...volunteers,
      {
        date: '',
        id: uuidv4(),
        volunteer: '',
        duration: 0,
        firstName: '',
        lastName: '',
        hours: '',
        mins: '',
      },
    ]);
  }

  async function getVolunteers() {
    try {
      const result: Person[] = await HttpClient.get('/volunteers');
      setAllVolunteers(result);
    } catch (error) {
      setErrorMessage('Could not load available volunteers. Please contact Rebecca.');
    }
  }

  function handleHoursChange(e: ChangeEvent<HTMLInputElement>, index: number) {
    const name = e.target.name;

    const updated = [...volunteers];

    if (name === 'hours') {
      updated[index].hours = e.target.value;
    } else {
      updated[index].mins = e.target.value;
    }

    setVolunteers(updated);
  }

  useEffect(() => {
    getVolunteers();
  }, []);

  return (
    <Container style={{ paddingTop: '1em', paddingBottom: '1em' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Add Volunteer Hours
        </Typography>
      </Grid>
      <ErrorBanner errorHeading='Error adding volunteer hours' errorMessage={errorMessage} />
      <Grid container marginBottom='1em'>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label='Volunteer Date'
              value={date}
              onChange={(e: any) => setDate(e)}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid>
        <Box marginBottom={'1em'}>
          <Typography variant='h6'>Volunteers</Typography>
        </Box>
        {volunteers.map((volunteer: VolunteerHour, index: number) => {
          return (
            <Grid
              key={index}
              container
              spacing={2}
              marginLeft={'-0.5em'}
              paddingBottom={'2em'}
              sx={{
                backgroundColor: errors.includes(volunteer.id!)
                  ? 'rgb(248, 215, 218)'
                  : index % 2 === 0
                  ? '#f5f5f5'
                  : 'white',
                paddingRight: '1em',
              }}
            >
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
                  getOptionKey={(option) => option.id}
                  value={volunteer.volunteer ? volunteer : null}
                  isOptionEqualToValue={(option, value) => option.id === value.volunteer}
                  options={allVolunteers.map((v) => {
                    return {
                      id: v.id,
                      firstName: v.firstName,
                      lastName: v.lastName,
                      volunteer: v.id,
                    };
                  })}
                  renderInput={(params) => <TextField {...params} label='Volunteer' />}
                  onChange={(event, selectedOption) => {
                    const updated = [...volunteers];

                    if (selectedOption) {
                      updated[index].volunteer = selectedOption.id;
                      updated[index].firstName = selectedOption.firstName;
                      updated[index].lastName = selectedOption.lastName;
                    }
                    setVolunteers(updated);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <HorseHours
                  hours={volunteer.hours!}
                  mins={volunteer.mins!}
                  handleChange={(event) => {
                    handleHoursChange(event, index);
                  }}
                  removeEnd
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid container spacing={2} justifyContent='center' marginTop='0.5em' marginBottom='1em'>
        <Grid item xs={8}>
          <Button variant='contained' color='inherit' onClick={addVolunteer} fullWidth>
            <AddSharpIcon style={{ width: '20', paddingBottom: '3.5px' }} />
            Add Volunteer
          </Button>
        </Grid>
      </Grid>
      <SubmitButton disabled={loading} handleSubmit={handleSubmit} />
    </Container>
  );
}

export default AddVolunteerHours;
