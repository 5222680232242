import ErrorBanner from '../../components/ErrorBanner';
import { useEffect, useState } from 'react';
import { HttpClient } from '../../helpers/httpClient';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Person, VolunteerHourAndName } from '../../types/People';
import { Button, Container, Grid, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EditVolunteer from './EditVolunteer';
import ConfirmDeletionModal from '../../components/Modals/ConfirmDeletionModal';

function ViewEditVolunteerHours() {
  const navigate = useNavigate();
  const params = useParams();
  const volunteer = params.volunteer!;

  const [volunteerHours, setVolunteerHours] = useState<VolunteerHourAndName[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);
  const [currentHour, setCurrentHour] = useState<
    VolunteerHourAndName & { hours?: string; mins?: string }
  >();
  const [mode, setMode] = useState<'view' | 'edit' | 'delete'>('view');

  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => (
        <strong>
          <Button
            variant='contained'
            color='success'
            id={`edir-${params.row.id}`}
            onClick={() => {
              setMode('edit');
              setCurrentHour(params.row);
            }}
            style={{ marginRight: '0.5em' }}
            disabled={buttonsDisabled}
          >
            <EditIcon style={{ width: '20', paddingBottom: '3.5px' }} />
          </Button>
          <Button
            variant='contained'
            color='error'
            id={`delete-${params.row.id}`}
            onClick={() => {
              setMode('delete');
              setCurrentHour(params.row);
            }}
            disabled={buttonsDisabled}
          >
            <DeleteIcon style={{ width: '20', paddingBottom: '3.5px' }} />
          </Button>
        </strong>
      ),
    },
    { field: 'date', headerName: 'Date', flex: 1, minWidth: 100 },
    {
      field: 'duration',
      headerName: 'Hours',
      flex: 1,
      type: 'number',
      minWidth: 110,
      renderCell: (params) => (params.row.duration / 60).toFixed(2),
    },
  ];

  async function getVolunteerHours() {
    try {
      const result: VolunteerHourAndName[] = await HttpClient.get(`/volunteers/hours/${volunteer}`);
      setVolunteerHours(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  async function handleDelete() {
    setButtonsDisabled(true);
    setErrorMessage('');
    if (currentHour) {
      try {
        const result = await HttpClient.delete(`/volunteers/hours/hour/${currentHour.id}`);
        console.log(result);
        setMode('view');
        await getVolunteerHours();
      } catch (error) {
        setErrorMessage((error as Error).message);
      }
    }

    setButtonsDisabled(false);
  }

  useEffect(() => {
    getVolunteerHours();
  }, []);

  return (
    <Container>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          View/Edit Volunteer Hours
        </Typography>
      </Grid>
      <ErrorBanner errorHeading='Error editing volunteer hours' errorMessage={errorMessage} />
      {mode === 'edit' ? (
        <EditVolunteer
          onClose={() => {
            setMode('view');
          }}
          onSubmit={() => {
            getVolunteerHours();
          }}
          volunteerHour={
            currentHour
              ? {
                  ...currentHour,
                  hours:
                    currentHour.hours === undefined
                      ? String(Math.floor(currentHour.duration / 60))
                      : currentHour.hours,
                  mins:
                    currentHour.mins === undefined
                      ? String(currentHour.duration % 60)
                      : currentHour.mins,
                }
              : undefined
          }
          setVolunteerHour={setCurrentHour}
          show={mode === 'edit'}
        />
      ) : null}
      <ConfirmDeletionModal
        show={mode === 'delete'}
        handleClose={() => setMode('view')}
        handleConfirm={handleDelete}
      />
      <div style={{ height: '70vh', width: '100%', marginTop: '1em' }}>
        <DataGrid
          rows={volunteerHours}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
        />
      </div>
      <Grid container style={{ padding: '1em' }}>
        <Grid item xs={12}>
          <Button
            variant='contained'
            color='success'
            id='button-return-to-usage'
            onClick={() => navigate(`/volunteers/hours`)}
            fullWidth
          >
            Return to View Volunteer Hours
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ViewEditVolunteerHours;
