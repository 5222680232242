import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HttpClient } from '../../helpers/httpClient';
import ErrorBanner from '../../components/ErrorBanner';
import { Person, RiderAbsence } from '../../types/People';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import AbsentRiderRow from '../../components/Riders/AbsentRiderRow';
import { Lesson } from '../../types/Lessons';
import { v4 as uuidv4 } from 'uuid';
import { SubmitButton } from '../../components/SubmitButton';
import { Button, Container, Grid, Typography } from '@mui/material';
import { Day } from '../Board/DayView';

function AddRiderAbsences() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [absentRiders, setAbsentRiders] = useState<(RiderAbsence & { error: boolean })[]>([
    {
      rider: '',
      absentDate: '',
      lesson: -1,
      id: uuidv4(),
      error: false,
      makeupDate: '',
      makeupLesson: '',
      makeupLessonTime: '',
    },
  ]);

  async function handleSubmit() {
    setLoading(true);

    const data: RiderAbsence[] = [];
    const errors: string[] = [];
    console.log(absentRiders);
    absentRiders.forEach((absence: RiderAbsence) => {
      if (!absence) {
        return;
      }

      if (
        !absence.rider ||
        !absence.lesson ||
        !absence.absentDate ||
        (absence.makeupDate && !absence.makeupLesson)
      ) {
        errors.push(absence.id!);
        return;
      }

      data.push({
        ...absence,
        makeupLesson: absence.makeupLesson ? absence.makeupLesson : undefined,
      });
    });

    if (errors.length > 0) {
      setErrorMessage('Missing rider, the lesson they will be absent from, or absent date.');

      const nextAbsences = [...absentRiders];
      nextAbsences.forEach((absence: any) => {
        if (errors.includes(absence.id)) {
          absence.error = true;
        } else {
          absence.error = false;
        }
      });
      console.log(nextAbsences);
      setAbsentRiders(nextAbsences);
      setLoading(false);
      return;
    }

    setErrorMessage('');
    try {
      const result = await HttpClient.post('/riders/absences', data);
      console.log(result);
      navigate(`/people`);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }

    setLoading(false);
  }

  function addAbsentRider() {
    setAbsentRiders([
      ...absentRiders,
      {
        rider: '',
        absentDate: '',
        lesson: -1,
        id: uuidv4(),
        error: false,
        makeupDate: '',
        makeupLesson: '',
        makeupLessonTime: '',
      },
    ]);
  }

  async function getLessons(setLessons: Function) {
    try {
      const result = await HttpClient.get('/lessons');
      setLessons(result);
    } catch (error) {
      setErrorMessage('Could not load available lessons. Please contact Rebecca.');
    }
  }

  useEffect(() => {
    getLessons(setLessons);
  }, []);

  return (
    <Container style={{ paddingTop: '1em', paddingBottom: '1em' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Add Rider Absences
        </Typography>
      </Grid>
      <ErrorBanner errorHeading='Error adding rider absences' errorMessage={errorMessage} />

      {absentRiders.map((absentRider: any, index: number) => {
        if (!absentRider) {
          return null;
        }

        return (
          <Grid
            key={`${absentRider.id}-row`}
            paddingLeft={'1em'}
            paddingRight={'1em'}
            paddingBottom={'1em'}
            sx={{
              backgroundColor: absentRider.error
                ? 'rgb(248, 215, 218)'
                : index % 2 === 0
                ? '#f5f5f5'
                : 'white',
            }}
          >
            <AbsentRiderRow
              lessons={lessons}
              absence={absentRider}
              updateAbsence={(absence: RiderAbsence & { error: boolean }) => {
                let nextAbsences = [...absentRiders];
                nextAbsences[index] = absence;
                setAbsentRiders(nextAbsences);
                console.log(nextAbsences);
              }}
              setErrorMessage={setErrorMessage}
            />
          </Grid>
        );
      })}
      <Grid container spacing={2} justifyContent='center' marginTop='0.5em'>
        <Grid item xs={8} marginBottom={'1em'}>
          <Button variant='contained' color='inherit' onClick={addAbsentRider} fullWidth>
            <AddSharpIcon style={{ width: '20', paddingBottom: '3.5px' }} />
            Add Rider
          </Button>
        </Grid>
      </Grid>
      <SubmitButton disabled={loading} handleSubmit={handleSubmit} />
    </Container>
  );
}

export default AddRiderAbsences;
