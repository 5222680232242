import { UnformattedDailyUsage } from '../../types/Usage';
import { useEffect, useState } from 'react';
import { HttpClient } from '../../helpers/httpClient';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDeletionModal from '../Modals/ConfirmDeletionModal';
import { Button, Grid, Typography } from '@mui/material';

const dayString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function DisplayFreeLungeUsage(props: {
  selectedDate: string;
  selectedHorse: string;
  setErrorMessage: Function;
}) {
  const [usage, setUsage] = useState<UnformattedDailyUsage[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);

  const { setErrorMessage, selectedHorse, selectedDate } = props;
  const dayOfWeek = dayString[new Date(selectedDate).getUTCDay()];

  async function handleDelete(usageId: number) {
    setButtonsDisabled(true);
    setErrorMessage('');
    try {
      const result = await HttpClient.delete(`/usage/${usageId}`);
      console.log(result);
      await getUsage(setUsage, setErrorMessage, selectedDate, selectedHorse);
      setShowModal(false);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }

    setButtonsDisabled(false);
  }

  async function getUsage(
    setUsage: Function,
    setErrorMessage: Function,
    selectedDate: string,
    selectedHorse: string
  ) {
    try {
      const result: UnformattedDailyUsage[] = await HttpClient.get(
        `/usage/day?date=${encodeURIComponent(selectedDate)}&horse=${encodeURIComponent(
          selectedHorse
        )}&formatted=false`
      );
      setUsage(
        result.filter((usage: UnformattedDailyUsage) => {
          return usage.usageType === 'FL';
        })
      );
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  useEffect(() => {
    getUsage(setUsage, setErrorMessage, selectedDate, selectedHorse);
  }, []);

  return (
    <>
      {usage.length > 0 ? (
        <Grid marginTop='1em'>
          <Typography variant='h5' fontWeight={'bold'} marginBottom='1em'>
            Free Lunges
          </Typography>
          <Grid container marginBottom='1em'>
            {usage.map((usage: UnformattedDailyUsage, index: number) => {
              return (
                <Grid
                  key={usage.id}
                  padding='1em'
                  container
                  sx={{
                    backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white',
                  }}
                >
                  <ConfirmDeletionModal
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                    handleConfirm={() => handleDelete(usage.id!)}
                  />
                  <Grid item xs={6}>
                    Free Lunge #{index + 1}
                  </Grid>
                  <Grid container item xs={6} justifyContent={'right'}>
                    <Button
                      variant='contained'
                      color='error'
                      id={`delete-${usage.id}`}
                      onClick={() => setShowModal(true)}
                      disabled={buttonsDisabled}
                    >
                      <DeleteIcon style={{ width: '20', paddingBottom: '3.5px' }} />
                    </Button>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Typography variant='caption' color={'grey'}>
            To add a free lunge for this horse please go to the Add Usage page
          </Typography>
        </Grid>
      ) : null}
    </>
  );
}

export default DisplayFreeLungeUsage;
