import { useEffect, useState } from 'react';
import { Horse } from '../../types/Horses';
import { useNavigate } from 'react-router-dom';
import { HttpClient } from '../../helpers/httpClient';
import ErrorBanner from '../../components/ErrorBanner';
import {
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { SubmitButton } from '../../components/SubmitButton';

function FarrierInfo() {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [horses, setHorses] = useState<Horse[]>([]);
  const [farrierType, setFarrierType] = useState<{
    [key: string]:
      | {
          type?: string;
          notes?: string;
        }
      | undefined;
  }>({});
  const [date, setDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const navigate = useNavigate();

  async function handleSubmit() {
    setLoading(true);

    const appointments = Object.keys(farrierType).map((key: string) => {
      if (!farrierType[key]?.type) {
        return;
      }

      return {
        horse: key,
        ...farrierType[key],
        appointmentDate: date,
      };
    });

    if (appointments.length === 0) {
      setErrorMessage(
        'All horses are set to Not Done. Please add farrier appointment data before submitting. '
      );
      setLoading(false);
      return;
    }

    try {
      const result = await HttpClient.post('/horses/farrier', appointments);
      navigate(`/horses`);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }

    setLoading(false);
  }

  async function getHorses() {
    try {
      const result: Horse[] = await HttpClient.get('/horses');
      setHorses(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  useEffect(() => {
    getHorses();
  }, []);

  return (
    <Container style={{ paddingTop: '1em' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Farrier
        </Typography>
      </Grid>
      <ErrorBanner errorHeading='Error adding farrier appointment' errorMessage={errorMessage} />
      <Grid item xs={12} marginBottom={'1em'}>
        <TextField
          label='Farrier Date'
          type='date'
          value={date}
          name='farrierDate'
          onChange={(event) => {
            setDate(event.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Grid>
      <>
        {horses.map((horse, index) => (
          <Grid
            container
            key={index}
            id={horse.id}
            columnSpacing={2}
            sx={{
              backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white',
            }}
            marginLeft='0em'
            paddingRight='1em'
            paddingTop='1em'
            paddingBottom='1em'
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Grid item xs={12} sm={4}>
              <Typography
                variant='body1'
                sx={{
                  fontWeight: 'bold',
                  marginTop: '0.5em',
                }}
              >
                {horse.barnName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} marginBottom={'0.5em'}>
              <FormControl component='fieldset'>
                <RadioGroup
                  row
                  aria-label='options'
                  name='options'
                  value={
                    farrierType[horse.id!] && farrierType[horse.id!]!.type
                      ? farrierType[horse.id!]!.type
                      : ''
                  }
                  onChange={(event) => {
                    const newFarrierType = { ...farrierType };
                    if (event.target.value) {
                      newFarrierType[horse.id!] = {
                        ...newFarrierType[horse.id!],
                        type: event.target.value,
                      };
                    } else {
                      delete newFarrierType[horse.id!];
                    }
                    setFarrierType(newFarrierType);
                  }}
                >
                  <FormControlLabel value='Trim' control={<Radio />} label='Trim' />
                  <FormControlLabel value='Front Shoes' control={<Radio />} label='Front Shoes' />
                  <FormControlLabel value='Back Shoes' control={<Radio />} label='Back Shoes' />
                  <FormControlLabel value='4 Shoes' control={<Radio />} label='4 Shoes' />
                  <FormControlLabel value='Other' control={<Radio />} label='Other' />
                  <FormControlLabel value='' control={<Radio />} label={<em>Not Done</em>} />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label='Notes'
                type='text'
                value={
                  farrierType[horse.id!] && farrierType[horse.id!]!.notes
                    ? farrierType[horse.id!]!.notes
                    : ''
                }
                placeholder='Notes'
                name='notes'
                onChange={(event) => {
                  const newFarrierType = { ...farrierType };
                  newFarrierType[horse.id!] = {
                    ...newFarrierType[horse.id!],
                    notes: event.target.value,
                  };
                  setFarrierType(newFarrierType);
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        ))}
      </>
      <SubmitButton handleSubmit={handleSubmit} disabled={loading} />
    </Container>
  );
}

export default FarrierInfo;
