import { useState } from 'react';
import BasicModal from './BasicModal';
import { Button } from '@mui/material';

function ConfirmDeletionModal(props: {
  show: boolean;
  handleClose: () => void;
  handleConfirm: Function;
  deletionText?: string;
}) {
  return (
    <BasicModal
      onClose={props.handleClose}
      open={props.show}
      title='Confirm Deletion'
      submitButton={{
        color: 'error',
        text: 'Delete',
        onClick: async (event) => {
          await props.handleConfirm();
        },
      }}
      closeButton
    >
      <p>{props.deletionText ? props.deletionText : 'Are you sure you want to delete?'}</p>
    </BasicModal>
  );
}

export default ConfirmDeletionModal;
