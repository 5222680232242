import { Box, Typography } from '@mui/material';
import HorseHeader from './HorseHeader';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

function HorseData(props: {
  title: string;
  onAddClick: () => void;
  rows: any[] | undefined;
  columns: GridColDef[];
  missingMessage: string;
}) {
  const { title, onAddClick, rows, columns, missingMessage } = props;

  return (
    <Box>
      <HorseHeader title={title} onClick={onAddClick} />
      {rows ? (
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          style={{ marginBottom: '1rem' }}
        />
      ) : (
        <Typography variant='body2' style={{ marginBottom: '1rem' }}>
          {missingMessage}
        </Typography>
      )}
    </Box>
  );
}

export default HorseData;
