import { ChangeEvent, ChangeEventHandler, MouseEventHandler, useState } from 'react';
import { FeedTime, FeedType, HorseFeed } from '../../types/Horses';
import ErrorBanner from '../ErrorBanner';
import { HttpClient } from '../../helpers/httpClient';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import BasicModal from '../Modals/BasicModal';
import { formatTime } from '../../helpers/utils';
import { Lesson } from '../../types/Lessons';
import dayjs from 'dayjs';

function AddLessonModal(props: {
  isFutureLesson?: boolean;
  personId: string;
  lessons: Lesson[];
  show: boolean;
  handleClose: () => void;
  handleConfirm: Function;
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [lessonToAdd, setLessonToAdd] = useState<{
    lessonDay?: string;
    lessonId?: string;
    lessonTime?: string;
  }>({ lessonDay: '', lessonId: '', lessonTime: '' });

  async function handleAddToLesson() {
    setErrorMessage('');

    if (!lessonToAdd.lessonId) {
      setErrorMessage('You must select a lesson day and time.');
      return;
    }

    try {
      const result = await HttpClient.post(
        `/riders/${props.personId}/lessons/${lessonToAdd.lessonId}`,
        {}
      );

      setLessonToAdd({ lessonDay: '', lessonId: '', lessonTime: '' });
      await props.handleConfirm();
      await props.handleClose();
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  return (
    <BasicModal
      open={props.show}
      onClose={props.handleClose}
      title='Add Lesson'
      submitButton={{
        color: 'success',
        text: 'Add',
        onClick: async (event) => {
          await handleAddToLesson();
        },
      }}
      closeButton
    >
      <ErrorBanner errorHeading='Error adding lesson' errorMessage={errorMessage} />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label='Lesson Day'
            select
            aria-label='Lesson Day'
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setLessonToAdd({ ...lessonToAdd, lessonDay: e.target.value });
            }}
            value={lessonToAdd.lessonDay}
            name={`lessonDay`}
            fullWidth
            required
          >
            <MenuItem value='Sunday'>Sunday</MenuItem>
            <MenuItem value='Monday'>Monday</MenuItem>
            <MenuItem value='Tuesday'>Tuesday</MenuItem>
            <MenuItem value='Wednesday'>Wednesday</MenuItem>
            <MenuItem value='Thursday'>Thursday</MenuItem>
            <MenuItem value='Friday'>Friday</MenuItem>
            <MenuItem value='Saturday'>Saturday</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label='Lesson Time'
            select
            aria-label='Lesson Time'
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setLessonToAdd({ ...lessonToAdd, lessonId: e.target.value });
            }}
            value={lessonToAdd.lessonId}
            name={`lessonId`}
            disabled={!lessonToAdd.lessonDay}
            helperText={!lessonToAdd.lessonDay ? 'Select a lesson day to see the times.' : ''}
            required
            fullWidth
          >
            {props.lessons
              .filter((l) => {
                return lessonToAdd.lessonDay === l.lessonDay;
              })
              .map((lesson) => (
                <MenuItem value={lesson.id} key={lesson.id}>
                  {props.isFutureLesson
                    ? `${lesson.lessonTime ? formatTime(lesson.lessonTime) : ''} (starting ${dayjs(
                        lesson.startDate
                      ).format('YYYY-MM-DD')})`
                    : `${lesson.lessonTime ? formatTime(lesson.lessonTime) : ''}`}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>
    </BasicModal>
  );
}

export default AddLessonModal;
