import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HttpClient } from '../../helpers/httpClient';
import ErrorBanner from '../../components/ErrorBanner';
import { Lesson } from '../../types/Lessons';
import { FormSubmitButton } from '../../components/SubmitButton';
import { Container, Grid, Typography } from '@mui/material';
import LessonData from './LessonData';

function AddLesson() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [lesson, setLesson] = useState<Lesson>({
    program: '',
    lessonType: '',
    startDate: '',
    endDate: '',
  });

  let lessonTimes = [];
  for (let i = 0; i < 24; i++) {
    let hour = i;
    let morningNight = ' AM';
    if (i == 0) {
      hour = 12;
    } else if (i == 12) {
      morningNight = ' PM';
    } else if (i > 12) {
      hour = i - 12;
      morningNight = ' PM';
    }

    lessonTimes.push(
      { value: `${i}:00`, display: `${hour}:00${morningNight}` },
      { value: `${i}:30`, display: `${hour}:30${morningNight}` }
    );
  }

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setErrorMessage('');

    try {
      if (form.checkValidity()) {
        const result = await HttpClient.post('/lessons', lesson);
        console.log(result);
        navigate('/lessons');
      }
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  };

  return (
    <Container style={{ paddingTop: '1em' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Add Lesson
        </Typography>
      </Grid>
      <ErrorBanner errorHeading='Error adding horse' errorMessage={errorMessage} />
      <form onSubmit={handleSubmit}>
        <LessonData lesson={lesson} setLesson={setLesson} />
        <FormSubmitButton />
      </form>
    </Container>
  );
}

export default AddLesson;
