import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Container,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import ErrorBanner from '../../components/ErrorBanner';
import { VolunteerHourResult } from '../../types/People';
import { HttpClient } from '../../helpers/httpClient';

function VolunteerHours() {
  const [errorMessage, setErrorMessage] = useState('');
  const [timeFilter, setTimeFilter] = useState<'day' | 'week' | 'month' | 'all'>('all');
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [date, setDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [volunteerHours, setVolunteerHours] = useState<VolunteerHourResult[]>([]);
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      minWidth: 90,
      maxWidth: 90,
      renderCell: (params) => (
        <strong>
          <Button
            variant='contained'
            color='success'
            onClick={() =>
              navigate(
                {
                  pathname: `/volunteers/hours/${params.row.volunteer}`,
                },
                {
                  state: {
                    person: params.row,
                  },
                }
              )
            }
          >
            <VisibilityIcon />
          </Button>
        </strong>
      ),
    },
    { field: 'firstName', headerName: 'First Name', flex: 1, minWidth: 150 },
    { field: 'lastName', headerName: 'Last Name', flex: 1, minWidth: 150 },
    {
      field: 'volunteerLevel',
      headerName: 'Volunteer Level',
      flex: 1,
      type: 'number',
      minWidth: 100,
    },
    {
      field: 'duration',
      headerName: 'Num Hours Worked',
      flex: 1,
      type: 'number',
      minWidth: 150,
      renderCell: (params) => (params.row.duration / 60).toFixed(2),
    },
  ];

  const isSaturday = (date: dayjs.Dayjs) => {
    return date.day() === 6;
  };

  const shouldDisableDate = (date: dayjs.Dayjs): boolean => {
    return timeFilter === 'week' && !isSaturday(date);
  };

  async function getVolunteerHours(timeFilter: string | null, date: dayjs.Dayjs | null) {
    setErrorMessage('');

    if (timeFilter == null) {
      timeFilter = 'all';
    }

    if (date === null) {
      return;
    }

    const dateString = date.format('YYYY-MM-DD');

    try {
      const result: VolunteerHourResult[] = await HttpClient.get(
        `/volunteers/hours?type=${encodeURIComponent(timeFilter)}&date=${encodeURIComponent(
          dateString
        )}`
      );
      console.log(result);
      setVolunteerHours(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  useEffect(() => {
    getVolunteerHours(timeFilter, date);
  }, [timeFilter, date]);

  return (
    <Container style={{ paddingTop: '1em' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Volunteer Hours
        </Typography>
      </Grid>
      <ErrorBanner errorHeading='Error getting volunteer hours' errorMessage={errorMessage} />
      <Grid
        container
        alignItems={'center'}
        spacing={2}
        marginBottom={'1em'}
        justifyContent={'center'}
      >
        <Grid item xs={12} sm={9}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={date}
              onChange={(value, context) => {
                setDate(value);
              }}
              shouldDisableDate={shouldDisableDate}
              slotProps={{ textField: { fullWidth: true } }}
              views={timeFilter === 'month' ? ['month', 'year'] : ['month', 'year', 'day']}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={3} sx={{ minWidth: '110px' }}>
          <Button
            fullWidth
            onClick={() =>
              navigate(
                {
                  pathname: '/volunteers/hours/new',
                },
                {
                  state: {
                    date,
                  },
                }
              )
            }
            variant='contained'
            color='success'
          >
            <AddSharpIcon style={{ width: '20', paddingBottom: '3.5px' }} /> Add New
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <ToggleButtonGroup
          value={timeFilter}
          exclusive
          onChange={(
            event: React.MouseEvent<HTMLElement>,
            newFilter: 'day' | 'week' | 'month' | 'all' | null
          ) => {
            if (newFilter === 'week') {
              let currentDate = dayjs(date);
              const daysBack = currentDate.day() !== 6 ? currentDate.day() + 1 : 0;
              currentDate = currentDate.subtract(daysBack, 'day');
              setDate(currentDate);
            }
            setTimeFilter(newFilter ?? 'all');
          }}
          aria-label='time'
          fullWidth
          color='success'
        >
          <ToggleButton value='all'>All</ToggleButton>
          <ToggleButton value='month'>Month</ToggleButton>
          <ToggleButton value='week'>Week</ToggleButton>
          <ToggleButton value='day'>Day</ToggleButton>
        </ToggleButtonGroup>
        <div style={{ height: '70vh', width: '100%', marginTop: '1em' }}>
          <DataGrid
            getRowId={(row) => row.volunteer}
            rows={volunteerHours}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </div>
      </Grid>
    </Container>
  );
}

export default VolunteerHours;
