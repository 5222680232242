import { Horse } from '../../types/Horses';
import { ChangeEvent, useState } from 'react';
import { HttpClient } from '../../helpers/httpClient';
import { useNavigate } from 'react-router-dom';
import { Usage } from '../../types/Usage';
import { SubmitButton } from '../SubmitButton';
import dayjs from 'dayjs';
import { Checkbox, Grid } from '@mui/material';

type KeyValue = {
  [key: string]: boolean;
};

function AddFreeLunges(props: { horses: Horse[]; setErrorMessage: Function; date: dayjs.Dayjs }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  console.log(loading);

  const defaultUsage: KeyValue = {};
  for (const key of props.horses) {
    defaultUsage[key.id!] = false;
  }

  const [horseHours, setHorseHours] = useState<KeyValue>(defaultUsage);
  const [errors, setErrors] = useState<string[]>([]);

  async function handleSubmit() {
    setLoading(true);
    let usage: Usage[] = [];
    let errors: string[] = [];

    Object.keys(horseHours).forEach((key) => {
      if (horseHours[key]) {
        usage.push({
          usageType: 'FL',
          horse: key,
          duration: 10,
          usageDate: props.date.format('YYYY-MM-DD'),
        });
      }
    });

    if (usage.length <= 0) {
      errors.push('Must have at least one horse free lunged');
    }

    setErrors(errors);
    if (errors.length > 0) {
      setLoading(false);
      return;
    }

    try {
      await HttpClient.post('/usage', usage);
      navigate(`/usage/${props.date.format('YYYY-MM-DD')}`);
    } catch (error) {
      props.setErrorMessage((error as Error).message);
    }

    setLoading(false);
  }

  function handleInputChange(e: ChangeEvent<HTMLInputElement>, horseId?: string) {
    if (horseId) {
      const updated = {
        ...horseHours,
      };

      updated[horseId] = e.target.checked;

      setHorseHours(updated);
    }
  }

  return (
    <Grid container marginBottom='1em'>
      <Grid container marginBottom='1em' columnSpacing={2} marginLeft='0em'>
        <Grid item xs={6} sx={{ fontWeight: 'bold' }}>
          Horse
        </Grid>
        <Grid item xs={6} sx={{ fontWeight: 'bold' }}>
          Free Lunged
        </Grid>
      </Grid>

      {props.horses.map((horse, index) => (
        <Grid
          columnSpacing={2}
          container
          key={index}
          id={horse.id}
          sx={{
            backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white',
          }}
          alignItems={'center'}
          justifyContent={'center'}
          marginLeft='0em'
        >
          <Grid item xs={6} alignItems={'center'}>
            {horse.barnName}
          </Grid>

          <Grid item xs={6}>
            <Checkbox
              onChange={(event) => {
                handleInputChange(event, horse.id);
              }}
            />
          </Grid>
        </Grid>
      ))}
      <Grid container marginTop='1.5em'>
        <SubmitButton disabled={loading} handleSubmit={handleSubmit} />
      </Grid>
    </Grid>
  );
}

export default AddFreeLunges;
