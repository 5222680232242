import { ChangeEvent } from 'react';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { RiderLesson } from '../../types/People';
import { Lesson } from '../../types/Lessons';
import { formatTime } from '../../helpers/utils';
import { Day } from '../../types/Day';
import KnowledgeableSelect from '../KnowledgeableSelect';
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';

function AddLessons(props: {
  riderLessons: RiderLesson[];
  setRiderLessons: Function;
  lessons: Lesson[];
}) {
  const handleLessonInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newValue: string | number = value;
    const index = Number(name.split('-')[1]);

    let newRiderLessons = [...props.riderLessons];

    const day = newValue!;
    newRiderLessons[index] = {
      ...newRiderLessons[index],
      lessonDay: day as Day,
    };

    console.log(newRiderLessons);
    props.setRiderLessons(newRiderLessons);
  };

  function addLesson() {
    props.setRiderLessons([
      ...props.riderLessons,
      { lessonDay: '', lessonTime: '', currentLesson: true },
    ]);
  }

  return (
    <>
      <Typography variant='h4' marginTop={'0.5em'}>
        Lesson Info
      </Typography>
      {props.riderLessons.map((riderLesson, index) => {
        return (
          <Grid container spacing={2} key={index} marginTop='0.5em'>
            <Grid item xs={12} sm={4}>
              <TextField
                label='Lesson Start'
                select
                aria-label='Lesson Start'
                onChange={(event) => {
                  let newRiderLessons = [...props.riderLessons];
                  newRiderLessons[index] = {
                    ...newRiderLessons[index],
                    currentLesson: event.target.value === 'Running now',
                  };
                  props.setRiderLessons(newRiderLessons);
                }}
                value={props.riderLessons[index].currentLesson ? 'Running now' : 'Future lesson'}
                name='lessonStart'
                fullWidth
                required
              >
                <MenuItem value='Running now'>Running now</MenuItem>
                <MenuItem value='Future lesson'>Future lesson</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label='Lesson Day'
                select
                aria-label='Lesson Day'
                onChange={handleLessonInputChange}
                value={
                  props.riderLessons[index].lessonDay ? props.riderLessons[index].lessonDay : ''
                }
                name={`lessonDay-${index}`}
                fullWidth
                required
              >
                <MenuItem value='Sunday'>Sunday</MenuItem>
                <MenuItem value='Monday'>Monday</MenuItem>
                <MenuItem value='Tuesday'>Tuesday</MenuItem>
                <MenuItem value='Wednesday'>Wednesday</MenuItem>
                <MenuItem value='Thursday'>Thursday</MenuItem>
                <MenuItem value='Friday'>Friday</MenuItem>
                <MenuItem value='Saturday'>Saturday</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <KnowledgeableSelect
                options={props.lessons
                  .filter((l: Lesson) => {
                    // lesson is on that day && is currently running (if want currently running) or not currently running (if want future)
                    return (
                      riderLesson.lessonDay === l.lessonDay &&
                      l.currentLesson == riderLesson.currentLesson
                    );
                  })
                  .map((lesson) => {
                    return {
                      display:
                        formatTime(lesson.lessonTime) +
                        `${
                          !riderLesson.currentLesson
                            ? ' (starting ' + dayjs(lesson.startDate).format('YYYY-MM-DD') + ')'
                            : ''
                        }`,
                      otherDetails: lesson,
                    };
                  })}
                onChange={(selectedOption: Lesson | undefined) => {
                  let newRiderLessons = [...props.riderLessons];
                  newRiderLessons[index] = {
                    ...newRiderLessons[index],
                    lessonId: selectedOption?.id,
                    lessonTime: selectedOption?.lessonTime,
                  };
                  props.setRiderLessons(newRiderLessons);
                }}
                disabled={!props.riderLessons[index].lessonDay}
                required={true}
                id={`lessonInput-${index}`}
                missingRelatedFieldText='Select a lesson day to see the times.'
                label='Lesson Time'
                initialSelected={-1}
                defaultOption={{
                  program: '',
                  lessonType: '',
                  startDate: '',
                  endDate: '',
                }}
              />
            </Grid>
          </Grid>
        );
      })}
      <Grid container spacing={2} justifyContent='center' marginTop='0.5em'>
        <Grid item xs={8}>
          <Button variant='contained' color='inherit' onClick={addLesson} fullWidth>
            <AddSharpIcon style={{ width: '20', paddingBottom: '3.5px' }} />
            Add Lesson
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default AddLessons;
